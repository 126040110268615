/**
 * Norwester
 */
@font-face {
  font-family: 'norwesterregular';
  src: url('../fonts/norwester-webfont.woff2') format('woff2'),
       url('../fonts/norwester-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/**
 * BlackTie
 */
@font-face {
  font-family: "SS BlackTie-Regular";
  src: url('../fonts/ss-black-tie-regular.eot');
  src: url('../fonts/ss-black-tie-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/ss-black-tie-regular.woff') format('woff'), url('../fonts/ss-black-tie-regular.ttf') format('truetype'), url('../fonts/ss-black-tie-regular.svg#BlackTie-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SS BlackTie-Bold";
  src: url('../fonts/ss-black-tie-bold.eot');
  src: url('../fonts/ss-black-tie-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/ss-black-tie-bold.woff') format('woff'), url('../fonts/ss-black-tie-bold.ttf') format('truetype'), url('../fonts/ss-black-tie-bold.svg#BlackTie-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
html:hover [class^="ss-"] {
  -ms-zoom: 1;
}
.ss-icon, [class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after {
  font-style: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="ss-"].right:before,
[class*=" ss-"].right:before {
  display: none;
  content: '';
 }

.ss-icon, [class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after,
.ss-icon.ss-black-tie-regular,
[class^="ss-"].ss-black-tie-regular:before,
[class*=" ss-"].ss-black-tie-regular:before,
[class^="ss-"].ss-black-tie-regular.right:after,
[class*=" ss-"].ss-black-tie-regular.right:after {
    font-weight: normal;
    font-family: "SS BlackTie-Regular";
 }

.ss-icon, [class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after,
.ss-icon.ss-black-tie-bold,
[class^="ss-"].ss-black-tie-bold:before,
[class*=" ss-"].ss-black-tie-bold:before,
[class^="ss-"].ss-black-tie-bold.right:after,
[class*=" ss-"].ss-black-tie-bold.right:after {
  font-weight: normal;
  font-family: "SS BlackTie-Bold";
}

.ss-rows:before,
.ss-rows.right:after {
  content: '';
}
.ss-search:before,
.ss-search.right:after {
  content: '🔎';
}
.ss-zoomout:before,
.ss-zoomout.right:after {
  content: '';
}
.ss-ellipsischat:before,
.ss-ellipsischat.right:after {
  content: '';
}
.ss-rss:before,
.ss-rss.right:after {
  content: '';
}
.ss-halfstar:before,
.ss-halfstar.right:after {
  content: '';
}
.ss-checkcircle:before,
.ss-checkcircle.right:after {
  content: '';
}
.ss-checksquare:before,
.ss-checksquare.right:after {
  content: '';
}
.ss-grid:before,
.ss-grid.right:after {
  content: '';
}
.ss-thumbnails:before,
.ss-thumbnails.right:after {
  content: '';
}
.ss-pluscircle:before,
.ss-pluscircle.right:after {
  content: '';
}
.ss-plussquare:before,
.ss-plussquare.right:after {
  content: '';
}
.ss-pen:before,
.ss-pen.right:after {
  content: '✒';
}
.ss-hyphencircle:before,
.ss-hyphencircle.right:after {
  content: '';
}
.ss-hyphensquare:before,
.ss-hyphensquare.right:after {
  content: '';
}
.ss-compose:before,
.ss-compose.right:after {
  content: '📝';
}
.ss-rocket:before,
.ss-rocket.right:after {
  content: '🚀';
}
.ss-book:before,
.ss-book.right:after {
  content: '📕';
}
.ss-openbook:before,
.ss-openbook.right:after {
  content: '📖';
}
.ss-notebook:before,
.ss-notebook.right:after {
  content: '📓';
}
.ss-camera:before,
.ss-camera.right:after {
  content: '📷';
}
.ss-folder:before,
.ss-folder.right:after {
  content: '📁';
}
.ss-unquote:before,
.ss-unquote.right:after {
  content: '”';
}
.ss-loading:before,
.ss-loading.right:after {
  content: '';
}
.ss-wrench:before,
.ss-wrench.right:after {
  content: '🔧';
}
.ss-cloud:before,
.ss-cloud.right:after {
  content: '☁';
}
.ss-uploadcloud:before,
.ss-uploadcloud.right:after {
  content: '';
}
.ss-downloadcloud:before,
.ss-downloadcloud.right:after {
  content: '';
}
.ss-share:before,
.ss-share.right:after {
  content: '';
}
.ss-film:before,
.ss-film.right:after {
  content: '';
}
.ss-trophy:before,
.ss-trophy.right:after {
  content: '🏆';
}
.ss-code:before,
.ss-code.right:after {
  content: '';
}
.ss-fax:before,
.ss-fax.right:after {
  content: '📠';
}
.ss-flowchart:before,
.ss-flowchart.right:after {
  content: '';
}
.ss-upload:before,
.ss-upload.right:after {
  content: '';
}
.ss-download:before,
.ss-download.right:after {
  content: '';
}
.ss-key:before,
.ss-key.right:after {
  content: '🔑';
}
.ss-mug:before,
.ss-mug.right:after {
  content: '☕';
}
.ss-bookmark:before,
.ss-bookmark.right:after {
  content: '🔖';
}
.ss-flag:before,
.ss-flag.right:after {
  content: '⚑';
}
.ss-redirect:before,
.ss-redirect.right:after {
  content: '↪';
}
.ss-smile:before,
.ss-smile.right:after {
  content: '☻';
}
.ss-frown:before,
.ss-frown.right:after {
  content: '☹';
}
.ss-magicwand:before,
.ss-magicwand.right:after {
  content: '';
}
.ss-lightning:before,
.ss-lightning.right:after {
  content: '☇';
}
.ss-flask:before,
.ss-flask.right:after {
  content: '';
}
.ss-pushpin:before,
.ss-pushpin.right:after {
  content: '📌';
}
.ss-shield:before,
.ss-shield.right:after {
  content: '';
}
.ss-sort:before,
.ss-sort.right:after {
  content: '';
}
.ss-reply:before,
.ss-reply.right:after {
  content: '↩';
}
.ss-forward:before,
.ss-forward.right:after {
  content: '';
}
.ss-replyall:before,
.ss-replyall.right:after {
  content: '';
}
.ss-forwardall:before,
.ss-forwardall.right:after {
  content: '';
}
.ss-ban:before,
.ss-ban.right:after {
  content: '🚫';
}
.ss-database:before,
.ss-database.right:after {
  content: '';
}
.ss-merge:before,
.ss-merge.right:after {
  content: '';
}
.ss-fork:before,
.ss-fork.right:after {
  content: '';
}
.ss-wifi:before,
.ss-wifi.right:after {
  content: '';
}
.ss-paperairplane:before,
.ss-paperairplane.right:after {
  content: '';
}
.ss-inbox:before,
.ss-inbox.right:after {
  content: '📥';
}
.ss-campfire:before,
.ss-campfire.right:after {
  content: '🔥';
}
.ss-play:before,
.ss-play.right:after {
  content: '▶';
}
.ss-pause:before,
.ss-pause.right:after {
  content: '';
}
.ss-playcircle:before,
.ss-playcircle.right:after {
  content: '';
}
.ss-skipback:before,
.ss-skipback.right:after {
  content: '⏮';
}
.ss-repeat:before,
.ss-repeat.right:after {
  content: '🔁';
}
.ss-fastforward:before,
.ss-fastforward.right:after {
  content: '⏩';
}
.ss-rewind:before,
.ss-rewind.right:after {
  content: '⏪';
}
.ss-scaleup:before,
.ss-scaleup.right:after {
  content: '';
}
.ss-scaledown:before,
.ss-scaledown.right:after {
  content: '';
}
.ss-closedcaption:before,
.ss-closedcaption.right:after {
  content: '';
}
.ss-shuffle:before,
.ss-shuffle.right:after {
  content: '🔀';
}
.ss-triangle:before,
.ss-triangle.right:after {
  content: '▲';
}
.ss-circle:before,
.ss-circle.right:after {
  content: '○';
}
.ss-hexagon:before,
.ss-hexagon.right:after {
  content: '⬡';
}
.ss-octagon:before,
.ss-octagon.right:after {
  content: '⯃';
}
.ss-navigateup:before,
.ss-navigateup.right:after {
  content: '';
}
.ss-navigatedown:before,
.ss-navigatedown.right:after {
  content: '';
}
.ss-anglesup:before,
.ss-anglesup.right:after {
  content: '';
}
.ss-anglesdown:before,
.ss-anglesdown.right:after {
  content: '';
}
.ss-anglesleft:before,
.ss-anglesleft.right:after {
  content: '';
}
.ss-anglesright:before,
.ss-anglesright.right:after {
  content: '';
}
.ss-up:before,
.ss-up.right:after {
  content: '⬆';
}
.ss-down:before,
.ss-down.right:after {
  content: '⬇';
}
.ss-barchart:before,
.ss-barchart.right:after {
  content: '📊';
}
.ss-piechart:before,
.ss-piechart.right:after {
  content: '';
}
.ss-circlearrowup:before,
.ss-circlearrowup.right:after {
  content: '⮉';
}
.ss-circlearrowdown:before,
.ss-circlearrowdown.right:after {
  content: '⮋';
}
.ss-circlearrowleft:before,
.ss-circlearrowleft.right:after {
  content: '⮈';
}
.ss-circlearrowright:before,
.ss-circlearrowright.right:after {
  content: '⮊';
}
.ss-directleft:before,
.ss-directleft.right:after {
  content: '◃';
}
.ss-directright:before,
.ss-directright.right:after {
  content: '▹';
}
.ss-longarrowup:before,
.ss-longarrowup.right:after {
  content: '⭡';
}
.ss-longarrowdown:before,
.ss-longarrowdown.right:after {
  content: '⭣';
}
.ss-longarrowleft:before,
.ss-longarrowleft.right:after {
  content: '⭠';
}
.ss-longarrowright:before,
.ss-longarrowright.right:after {
  content: '⭢';
}
.ss-bold:before,
.ss-bold.right:after {
  content: '';
}
.ss-italics:before,
.ss-italics.right:after {
  content: '';
}
.ss-underline:before,
.ss-underline.right:after {
  content: '';
}
.ss-link:before,
.ss-link.right:after {
  content: '🔗';
}
.ss-alignleft:before,
.ss-alignleft.right:after {
  content: '';
}
.ss-aligncenter:before,
.ss-aligncenter.right:after {
  content: '';
}
.ss-alignright:before,
.ss-alignright.right:after {
  content: '';
}
.ss-alignjustify:before,
.ss-alignjustify.right:after {
  content: '';
}
.ss-htmltable:before,
.ss-htmltable.right:after {
  content: '';
}
.ss-indent:before,
.ss-indent.right:after {
  content: '';
}
.ss-outdent:before,
.ss-outdent.right:after {
  content: '';
}
.ss-textsize:before,
.ss-textsize.right:after {
  content: '';
}
.ss-textcolor:before,
.ss-textcolor.right:after {
  content: '';
}
.ss-removeformatting:before,
.ss-removeformatting.right:after {
  content: '';
}
.ss-globe:before,
.ss-globe.right:after {
  content: '🌐';
}
.ss-map:before,
.ss-map.right:after {
  content: '';
}
.ss-location:before,
.ss-location.right:after {
  content: '';
}
.ss-pin:before,
.ss-pin.right:after {
  content: '📍';
}
.ss-warehouse:before,
.ss-warehouse.right:after {
  content: '';
}
.ss-laptop:before,
.ss-laptop.right:after {
  content: '';
}
.ss-radiochecked:before,
.ss-radiochecked.right:after {
  content: '';
}
.ss-crown:before,
.ss-crown.right:after {
  content: '👑';
}
.ss-creditcard:before,
.ss-creditcard.right:after {
  content: '💳';
}
.ss-strikethrough:before,
.ss-strikethrough.right:after {
  content: '';
}
.ss-eject:before,
.ss-eject.right:after {
  content: '⏏';
}
.ss-ellipsis:before,
.ss-ellipsis.right:after {
  content: '…';
}
.ss-ellipsisvertical:before,
.ss-ellipsisvertical.right:after {
  content: '';
}
.ss-zoom:before,
.ss-zoom.right:after {
  content: '';
}
.ss-delete:before,
.ss-delete.right:after {
  content: '␡';
}
.ss-deletecircle:before,
.ss-deletecircle.right:after {
  content: '';
}
.ss-clock:before,
.ss-clock.right:after {
  content: '⏲';
}
.ss-star:before,
.ss-star.right:after {
  content: '⋆';
}
.ss-heart:before,
.ss-heart.right:after {
  content: '♥';
}
.ss-halfheart:before,
.ss-halfheart.right:after {
  content: '';
}
.ss-calendar:before,
.ss-calendar.right:after {
  content: '📅';
}
.ss-cart:before,
.ss-cart.right:after {
  content: '';
}
.ss-plus:before,
.ss-plus.right:after {
  content: '+';
}
.ss-trash:before,
.ss-trash.right:after {
  content: '';
}
.ss-info:before,
.ss-info.right:after {
  content: 'ℹ';
}
.ss-quote:before,
.ss-quote.right:after {
  content: '“';
}
.ss-gift:before,
.ss-gift.right:after {
  content: '🎁';
}
.ss-loadingcirlce:before,
.ss-loadingcirlce.right:after {
  content: '';
}
.ss-sync:before,
.ss-sync.right:after {
  content: '';
}
.ss-help:before,
.ss-help.right:after {
  content: '❓';
}
.ss-briefcase:before,
.ss-briefcase.right:after {
  content: '💼';
}
.ss-lightbulb:before,
.ss-lightbulb.right:after {
  content: '💡';
}
.ss-print:before,
.ss-print.right:after {
  content: '⎙';
}
.ss-cellbars:before,
.ss-cellbars.right:after {
  content: '📶';
}
.ss-neutralface:before,
.ss-neutralface.right:after {
  content: '😐';
}
.ss-alert:before,
.ss-alert.right:after {
  content: '⚠';
}
.ss-alertcirle:before,
.ss-alertcirle.right:after {
  content: '';
}
.ss-harddrive:before,
.ss-harddrive.right:after {
  content: '';
}
.ss-stop:before,
.ss-stop.right:after {
  content: '■';
}
.ss-skip:before,
.ss-skip.right:after {
  content: '⏭';
}
.ss-mutevolume:before,
.ss-mutevolume.right:after {
  content: '';
}
.ss-highvolume:before,
.ss-highvolume.right:after {
  content: '🔊';
}
.ss-lowvolume:before,
.ss-lowvolume.right:after {
  content: '🔉';
}
.ss-navigateleft:before,
.ss-navigateleft.right:after {
  content: '◅';
}
.ss-navigateright:before,
.ss-navigateright.right:after {
  content: '▻';
}
.ss-left:before,
.ss-left.right:after {
  content: '⬅';
}
.ss-right:before,
.ss-right.right:after {
  content: '➡';
}
.ss-cut:before,
.ss-cut.right:after {
  content: '✂';
}
.ss-clipboard:before,
.ss-clipboard.right:after {
  content: '📋';
}
.ss-superscript:before,
.ss-superscript.right:after {
  content: '';
}
.ss-subscript:before,
.ss-subscript.right:after {
  content: '';
}
.ss-home:before,
.ss-home.right:after {
  content: '⌂';
}
.ss-buildings:before,
.ss-buildings.right:after {
  content: '🏢';
}
.ss-tablet:before,
.ss-tablet.right:after {
  content: '';
}
.ss-tv:before,
.ss-tv.right:after {
  content: '📺';
}
.ss-femaleuser:before,
.ss-femaleuser.right:after {
  content: '👧';
}
.ss-mail:before,
.ss-mail.right:after {
  content: '✉';
}
.ss-phone:before,
.ss-phone.right:after {
  content: '📞';
}
.ss-chat:before,
.ss-chat.right:after {
  content: '💬';
}
.ss-chats:before,
.ss-chats.right:after {
  content: '🗪';
}
.ss-check:before,
.ss-check.right:after {
  content: '✓';
}
.ss-edit:before,
.ss-edit.right:after {
  content: '✎';
}
.ss-like:before,
.ss-like.right:after {
  content: '👍';
}
.ss-dislike:before,
.ss-dislike.right:after {
  content: '👎';
}
.ss-settings:before,
.ss-settings.right:after {
  content: '⚙';
}
.ss-file:before,
.ss-file.right:after {
  content: '📄';
}
.ss-cost:before,
.ss-cost.right:after {
  content: '💲';
}
.ss-megaphone:before,
.ss-megaphone.right:after {
  content: '📢';
}
.ss-login:before,
.ss-login.right:after {
  content: '';
}
.ss-exit:before,
.ss-exit.right:after {
  content: '';
}
.ss-video:before,
.ss-video.right:after {
  content: '📹';
}
.ss-music:before,
.ss-music.right:after {
  content: '♫';
}
.ss-notification:before,
.ss-notification.right:after {
  content: '🔔';
}
.ss-notificationsdisabled:before,
.ss-notificationsdisabled.right:after {
  content: '🔕';
}
.ss-volume:before,
.ss-volume.right:after {
  content: '🔈';
}
.ss-ascend:before,
.ss-ascend.right:after {
  content: '▴';
}
.ss-attach:before,
.ss-attach.right:after {
  content: '📎';
}
.ss-layers:before,
.ss-layers.right:after {
  content: '';
}
.ss-list:before,
.ss-list.right:after {
  content: '';
}
.ss-numberlist:before,
.ss-numberlist.right:after {
  content: '';
}
.ss-replay:before,
.ss-replay.right:after {
  content: '↺';
}
.ss-refresh:before,
.ss-refresh.right:after {
  content: '↻';
}
.ss-navigate:before,
.ss-navigate.right:after {
  content: '';
}
.ss-user:before,
.ss-user.right:after {
  content: '👤';
}
.ss-smartphone:before,
.ss-smartphone.right:after {
  content: '';
}
.ss-desktop:before,
.ss-desktop.right:after {
  content: '💻';
}
.ss-descend:before,
.ss-descend.right:after {
  content: '▾';
}
.ss-unlock:before,
.ss-unlock.right:after {
  content: '🔓';
}
.ss-lock:before,
.ss-lock.right:after {
  content: '🔒';
}
.ss-hyphen:before,
.ss-hyphen.right:after {
  content: '-';
}
.ss-view:before,
.ss-view.right:after {
  content: '👀';
}
.ss-tag:before,
.ss-tag.right:after {
  content: '';
}
.ss-picture:before,
.ss-picture.right:after {
  content: '🌄';
}


/**
 * Gizmo
 */

@font-face {
  font-family: "SSGizmo";
  src: url('../fonts/ss-gizmo.eot');
  src: url('../fonts/ss-gizmo.eot?#iefix') format('embedded-opentype'),
       url('../fonts/ss-gizmo.woff') format('woff'),
       url('../fonts/ss-gizmo.ttf')  format('truetype'),
       url('../fonts/ss-gizmo.svg#SSGizmo') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ss-icon, .ss-icon.ss-gizmo,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-gizmo:before, [class*=" ss-"].ss-gizmo:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-gizmo.right:after, [class*=" ss-"].ss-gizmo.right:after {
  font-family: "SSGizmo";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before,
[class*=" ss-"].ss-gizmo.right:before{
	display:none;content:'';
}
.ss-cursor:before,
.ss-cursor.right:after {
  content: '';
}
.ss-crosshair:before,
.ss-crosshair.right:after {
  content: '⌖';
}
.ss-search:before,
.ss-search.right:after {
  content: '🔎';
}
.ss-zoomin:before,
.ss-zoomin.right:after {
  content: '';
}
.ss-zoomout:before,
.ss-zoomout.right:after {
  content: '';
}
.ss-view:before,
.ss-view.right:after {
  content: '👀';
}
.ss-attach:before,
.ss-attach.right:after {
  content: '📎';
}
.ss-link:before,
.ss-link.right:after {
  content: '🔗';
}
.ss-unlink:before,
.ss-unlink.right:after {
  content: '';
}
.ss-move:before,
.ss-move.right:after {
  content: '';
}
.ss-write:before,
.ss-write.right:after {
  content: '✎';
}
.ss-writingdisabled:before,
.ss-writingdisabled.right:after {
  content: '';
}
.ss-erase:before,
.ss-erase.right:after {
  content: '✐';
}
.ss-compose:before,
.ss-compose.right:after {
  content: '📝';
}
.ss-lock:before,
.ss-lock.right:after {
  content: '🔒';
}
.ss-unlock:before,
.ss-unlock.right:after {
  content: '🔓';
}
.ss-key:before,
.ss-key.right:after {
  content: '🔑';
}
.ss-backspace:before,
.ss-backspace.right:after {
  content: '⌫';
}
.ss-ban:before,
.ss-ban.right:after {
  content: '🚫';
}
.ss-smoking:before,
.ss-smoking.right:after {
  content: '🚬';
}
.ss-nosmoking:before,
.ss-nosmoking.right:after {
  content: '🚭';
}
.ss-trash:before,
.ss-trash.right:after {
  content: '';
}
.ss-target:before,
.ss-target.right:after {
  content: '◎';
}
.ss-tag:before,
.ss-tag.right:after {
  content: '';
}
.ss-bookmark:before,
.ss-bookmark.right:after {
  content: '🔖';
}
.ss-flag:before,
.ss-flag.right:after {
  content: '⚑';
}
.ss-like:before,
.ss-like.right:after {
  content: '👍';
}
.ss-dislike:before,
.ss-dislike.right:after {
  content: '👎';
}
.ss-heart:before,
.ss-heart.right:after {
  content: '♥';
}
.ss-star:before,
.ss-star.right:after {
  content: '⋆';
}
.ss-sample:before,
.ss-sample.right:after {
  content: '';
}
.ss-crop:before,
.ss-crop.right:after {
  content: '';
}
.ss-layers:before,
.ss-layers.right:after {
  content: '';
}
.ss-layergroup:before,
.ss-layergroup.right:after {
  content: '';
}
.ss-pen:before,
.ss-pen.right:after {
  content: '✒';
}
.ss-bezier:before,
.ss-bezier.right:after {
  content: '';
}
.ss-pixels:before,
.ss-pixels.right:after {
  content: '';
}
.ss-phone:before,
.ss-phone.right:after {
  content: '📞';
}
.ss-phonedisabled:before,
.ss-phonedisabled.right:after {
  content: '';
}
.ss-touchtonephone:before,
.ss-touchtonephone.right:after {
  content: '☏';
}
.ss-mail:before,
.ss-mail.right:after {
  content: '✉';
}
.ss-inbox:before,
.ss-inbox.right:after {
  content: '📥';
}
.ss-outbox:before,
.ss-outbox.right:after {
  content: '📤';
}
.ss-chat:before,
.ss-chat.right:after {
  content: '💬';
}
.ss-user:before,
.ss-user.right:after {
  content: '👤';
}
.ss-users:before,
.ss-users.right:after {
  content: '👥';
}
.ss-usergroup:before,
.ss-usergroup.right:after {
  content: '';
}
.ss-businessuser:before,
.ss-businessuser.right:after {
  content: '';
}
.ss-man:before,
.ss-man.right:after {
  content: '👨';
}
.ss-male:before,
.ss-male.right:after {
  content: '';
}
.ss-woman:before,
.ss-woman.right:after {
  content: '👩';
}
.ss-female:before,
.ss-female.right:after {
  content: '';
}
.ss-raisedhand:before,
.ss-raisedhand.right:after {
  content: '✋';
}
.ss-hand:before,
.ss-hand.right:after {
  content: '';
}
.ss-pointup:before,
.ss-pointup.right:after {
  content: '👆';
}
.ss-pointupright:before,
.ss-pointupright.right:after {
  content: '';
}
.ss-pointright:before,
.ss-pointright.right:after {
  content: '👉';
}
.ss-pointdownright:before,
.ss-pointdownright.right:after {
  content: '';
}
.ss-pointdown:before,
.ss-pointdown.right:after {
  content: '👇';
}
.ss-pointdownleft:before,
.ss-pointdownleft.right:after {
  content: '';
}
.ss-pointleft:before,
.ss-pointleft.right:after {
  content: '👈';
}
.ss-pointupleft:before,
.ss-pointupleft.right:after {
  content: '';
}
.ss-cart:before,
.ss-cart.right:after {
  content: '';
}
.ss-creditcard:before,
.ss-creditcard.right:after {
  content: '💳';
}
.ss-calculator:before,
.ss-calculator.right:after {
  content: '';
}
.ss-barchart:before,
.ss-barchart.right:after {
  content: '📊';
}
.ss-piechart:before,
.ss-piechart.right:after {
  content: '';
}
.ss-box:before,
.ss-box.right:after {
  content: '📦';
}
.ss-home:before,
.ss-home.right:after {
  content: '⌂';
}
.ss-globe:before,
.ss-globe.right:after {
  content: '🌎';
}
.ss-navigate:before,
.ss-navigate.right:after {
  content: '';
}
.ss-compass:before,
.ss-compass.right:after {
  content: '';
}
.ss-signpost:before,
.ss-signpost.right:after {
  content: '';
}
.ss-location:before,
.ss-location.right:after {
  content: '';
}
.ss-floppydisk:before,
.ss-floppydisk.right:after {
  content: '💾';
}
.ss-database:before,
.ss-database.right:after {
  content: '';
}
.ss-hdd:before,
.ss-hdd.right:after {
  content: '';
}
.ss-microchip:before,
.ss-microchip.right:after {
  content: '';
}
.ss-music:before,
.ss-music.right:after {
  content: '♫';
}
.ss-headphones:before,
.ss-headphones.right:after {
  content: '🎧';
}
.ss-discdrive:before,
.ss-discdrive.right:after {
  content: '';
}
.ss-volume:before,
.ss-volume.right:after {
  content: '🔈';
}
.ss-lowvolume:before,
.ss-lowvolume.right:after {
  content: '🔉';
}
.ss-mediumvolume:before,
.ss-mediumvolume.right:after {
  content: '';
}
.ss-highvolume:before,
.ss-highvolume.right:after {
  content: '🔊';
}
.ss-airplay:before,
.ss-airplay.right:after {
  content: '';
}
.ss-camera:before,
.ss-camera.right:after {
  content: '📷';
}
.ss-picture:before,
.ss-picture.right:after {
  content: '🌄';
}
.ss-video:before,
.ss-video.right:after {
  content: '📹';
}
.ss-webcam:before,
.ss-webcam.right:after {
  content: '';
}
.ss-film:before,
.ss-film.right:after {
  content: '';
}
.ss-playvideo:before,
.ss-playvideo.right:after {
  content: '';
}
.ss-videogame:before,
.ss-videogame.right:after {
  content: '🎮';
}
.ss-play:before,
.ss-play.right:after {
  content: '▶';
}
.ss-pause:before,
.ss-pause.right:after {
  content: '';
}
.ss-stop:before,
.ss-stop.right:after {
  content: '■';
}
.ss-record:before,
.ss-record.right:after {
  content: '●';
}
.ss-rewind:before,
.ss-rewind.right:after {
  content: '⏪';
}
.ss-fastforward:before,
.ss-fastforward.right:after {
  content: '⏩';
}
.ss-skipback:before,
.ss-skipback.right:after {
  content: '⏮';
}
.ss-skipforward:before,
.ss-skipforward.right:after {
  content: '⏭';
}
.ss-eject:before,
.ss-eject.right:after {
  content: '⏏';
}
.ss-repeat:before,
.ss-repeat.right:after {
  content: '🔁';
}
.ss-replay:before,
.ss-replay.right:after {
  content: '↺';
}
.ss-shuffle:before,
.ss-shuffle.right:after {
  content: '🔀';
}
.ss-index:before,
.ss-index.right:after {
  content: '';
}
.ss-storagebox:before,
.ss-storagebox.right:after {
  content: '';
}
.ss-book:before,
.ss-book.right:after {
  content: '📕';
}
.ss-notebook:before,
.ss-notebook.right:after {
  content: '📓';
}
.ss-newspaper:before,
.ss-newspaper.right:after {
  content: '📰';
}
.ss-gridlines:before,
.ss-gridlines.right:after {
  content: '';
}
.ss-rows:before,
.ss-rows.right:after {
  content: '';
}
.ss-columns:before,
.ss-columns.right:after {
  content: '';
}
.ss-thumbnails:before,
.ss-thumbnails.right:after {
  content: '';
}
.ss-mouse:before,
.ss-mouse.right:after {
  content: '';
}
.ss-usb:before,
.ss-usb.right:after {
  content: '';
}
.ss-desktop:before,
.ss-desktop.right:after {
  content: '💻';
}
.ss-laptop:before,
.ss-laptop.right:after {
  content: '';
}
.ss-tablet:before,
.ss-tablet.right:after {
  content: '';
}
.ss-smartphone:before,
.ss-smartphone.right:after {
  content: '';
}
.ss-cell:before,
.ss-cell.right:after {
  content: '📱';
}
.ss-battery:before,
.ss-battery.right:after {
  content: '🔋';
}
.ss-highbattery:before,
.ss-highbattery.right:after {
  content: '';
}
.ss-mediumbattery:before,
.ss-mediumbattery.right:after {
  content: '';
}
.ss-lowbattery:before,
.ss-lowbattery.right:after {
  content: '';
}
.ss-chargingbattery:before,
.ss-chargingbattery.right:after {
  content: '';
}
.ss-lightbulb:before,
.ss-lightbulb.right:after {
  content: '💡';
}
.ss-washer:before,
.ss-washer.right:after {
  content: '';
}
.ss-downloadcloud:before,
.ss-downloadcloud.right:after {
  content: '';
}
.ss-download:before,
.ss-download.right:after {
  content: '';
}
.ss-downloadbox:before,
.ss-downloadbox.right:after {
  content: '';
}
.ss-uploadcloud:before,
.ss-uploadcloud.right:after {
  content: '';
}
.ss-upload:before,
.ss-upload.right:after {
  content: '';
}
.ss-uploadbox:before,
.ss-uploadbox.right:after {
  content: '';
}
.ss-fork:before,
.ss-fork.right:after {
  content: '';
}
.ss-merge:before,
.ss-merge.right:after {
  content: '';
}
.ss-refresh:before,
.ss-refresh.right:after {
  content: '↻';
}
.ss-sync:before,
.ss-sync.right:after {
  content: '';
}
.ss-loading:before,
.ss-loading.right:after {
  content: '';
}
.ss-file:before,
.ss-file.right:after {
  content: '📄';
}
.ss-files:before,
.ss-files.right:after {
  content: '';
}
.ss-addfile:before,
.ss-addfile.right:after {
  content: '';
}
.ss-removefile:before,
.ss-removefile.right:after {
  content: '';
}
.ss-checkfile:before,
.ss-checkfile.right:after {
  content: '';
}
.ss-deletefile:before,
.ss-deletefile.right:after {
  content: '';
}
.ss-exe:before,
.ss-exe.right:after {
  content: '';
}
.ss-zip:before,
.ss-zip.right:after {
  content: '';
}
.ss-doc:before,
.ss-doc.right:after {
  content: '';
}
.ss-pdf:before,
.ss-pdf.right:after {
  content: '';
}
.ss-jpg:before,
.ss-jpg.right:after {
  content: '';
}
.ss-png:before,
.ss-png.right:after {
  content: '';
}
.ss-mp3:before,
.ss-mp3.right:after {
  content: '';
}
.ss-rar:before,
.ss-rar.right:after {
  content: '';
}
.ss-gif:before,
.ss-gif.right:after {
  content: '';
}
.ss-folder:before,
.ss-folder.right:after {
  content: '📁';
}
.ss-openfolder:before,
.ss-openfolder.right:after {
  content: '📂';
}
.ss-downloadfolder:before,
.ss-downloadfolder.right:after {
  content: '';
}
.ss-uploadfolder:before,
.ss-uploadfolder.right:after {
  content: '';
}
.ss-quote:before,
.ss-quote.right:after {
  content: '“';
}
.ss-unquote:before,
.ss-unquote.right:after {
  content: '”';
}
.ss-print:before,
.ss-print.right:after {
  content: '⎙';
}
.ss-copier:before,
.ss-copier.right:after {
  content: '';
}
.ss-fax:before,
.ss-fax.right:after {
  content: '📠';
}
.ss-scanner:before,
.ss-scanner.right:after {
  content: '';
}
.ss-printregistration:before,
.ss-printregistration.right:after {
  content: '';
}
.ss-shredder:before,
.ss-shredder.right:after {
  content: '';
}
.ss-expand:before,
.ss-expand.right:after {
  content: '⤢';
}
.ss-contract:before,
.ss-contract.right:after {
  content: '';
}
.ss-help:before,
.ss-help.right:after {
  content: '❓';
}
.ss-info:before,
.ss-info.right:after {
  content: 'ℹ';
}
.ss-alert:before,
.ss-alert.right:after {
  content: '⚠';
}
.ss-caution:before,
.ss-caution.right:after {
  content: '⛔';
}
.ss-logout:before,
.ss-logout.right:after {
  content: '';
}
.ss-login:before,
.ss-login.right:after {
  content: '';
}
.ss-scaleup:before,
.ss-scaleup.right:after {
  content: '';
}
.ss-scaledown:before,
.ss-scaledown.right:after {
  content: '';
}
.ss-plus:before,
.ss-plus.right:after {
  content: '+';
}
.ss-hyphen:before,
.ss-hyphen.right:after {
  content: '-';
}
.ss-check:before,
.ss-check.right:after {
  content: '✓';
}
.ss-delete:before,
.ss-delete.right:after {
  content: '␡';
}
.ss-notifications:before,
.ss-notifications.right:after {
  content: '🔔';
}
.ss-notificationsdisabled:before,
.ss-notificationsdisabled.right:after {
  content: '🔕';
}
.ss-clock:before,
.ss-clock.right:after {
  content: '⏲';
}
.ss-stopwatch:before,
.ss-stopwatch.right:after {
  content: '⏱';
}
.ss-alarmclock:before,
.ss-alarmclock.right:after {
  content: '⏰';
}
.ss-egg:before,
.ss-egg.right:after {
  content: '';
}
.ss-eggs:before,
.ss-eggs.right:after {
  content: '';
}
.ss-cheese:before,
.ss-cheese.right:after {
  content: '';
}
.ss-chickenleg:before,
.ss-chickenleg.right:after {
  content: '🍗';
}
.ss-pizzapie:before,
.ss-pizzapie.right:after {
  content: '';
}
.ss-pizza:before,
.ss-pizza.right:after {
  content: '🍕';
}
.ss-cheesepizza:before,
.ss-cheesepizza.right:after {
  content: '';
}
.ss-frenchfries:before,
.ss-frenchfries.right:after {
  content: '🍟';
}
.ss-apple:before,
.ss-apple.right:after {
  content: '🍏';
}
.ss-carrot:before,
.ss-carrot.right:after {
  content: '';
}
.ss-broccoli:before,
.ss-broccoli.right:after {
  content: '';
}
.ss-cucumber:before,
.ss-cucumber.right:after {
  content: '';
}
.ss-orange:before,
.ss-orange.right:after {
  content: '🍊';
}
.ss-lemon:before,
.ss-lemon.right:after {
  content: '🍋';
}
.ss-onion:before,
.ss-onion.right:after {
  content: '';
}
.ss-bellpepper:before,
.ss-bellpepper.right:after {
  content: '';
}
.ss-peas:before,
.ss-peas.right:after {
  content: '';
}
.ss-grapes:before,
.ss-grapes.right:after {
  content: '🍇';
}
.ss-strawberry:before,
.ss-strawberry.right:after {
  content: '🍓';
}
.ss-bread:before,
.ss-bread.right:after {
  content: '🍞';
}
.ss-mug:before,
.ss-mug.right:after {
  content: '☕';
}
.ss-mugs:before,
.ss-mugs.right:after {
  content: '';
}
.ss-espresso:before,
.ss-espresso.right:after {
  content: '';
}
.ss-macchiato:before,
.ss-macchiato.right:after {
  content: '';
}
.ss-cappucino:before,
.ss-cappucino.right:after {
  content: '';
}
.ss-latte:before,
.ss-latte.right:after {
  content: '';
}
.ss-icedcoffee:before,
.ss-icedcoffee.right:after {
  content: '';
}
.ss-coffeebean:before,
.ss-coffeebean.right:after {
  content: '';
}
.ss-coffeemilk:before,
.ss-coffeemilk.right:after {
  content: '';
}
.ss-coffeefoam:before,
.ss-coffeefoam.right:after {
  content: '';
}
.ss-coffeesugar:before,
.ss-coffeesugar.right:after {
  content: '';
}
.ss-sugarpackets:before,
.ss-sugarpackets.right:after {
  content: '';
}
.ss-capsule:before,
.ss-capsule.right:after {
  content: '';
}
.ss-capsulerecycling:before,
.ss-capsulerecycling.right:after {
  content: '';
}
.ss-insertcapsule:before,
.ss-insertcapsule.right:after {
  content: '';
}
.ss-tea:before,
.ss-tea.right:after {
  content: '🍵';
}
.ss-teabag:before,
.ss-teabag.right:after {
  content: '';
}
.ss-jug:before,
.ss-jug.right:after {
  content: '';
}
.ss-pitcher:before,
.ss-pitcher.right:after {
  content: '';
}
.ss-kettle:before,
.ss-kettle.right:after {
  content: '';
}
.ss-wineglass:before,
.ss-wineglass.right:after {
  content: '🍷';
}
.ss-sugar:before,
.ss-sugar.right:after {
  content: '';
}
.ss-oven:before,
.ss-oven.right:after {
  content: '';
}
.ss-stove:before,
.ss-stove.right:after {
  content: '';
}
.ss-vent:before,
.ss-vent.right:after {
  content: '';
}
.ss-exhaust:before,
.ss-exhaust.right:after {
  content: '';
}
.ss-steam:before,
.ss-steam.right:after {
  content: '♨';
}
.ss-dishwasher:before,
.ss-dishwasher.right:after {
  content: '';
}
.ss-toaster:before,
.ss-toaster.right:after {
  content: '';
}
.ss-microwave:before,
.ss-microwave.right:after {
  content: '';
}
.ss-electrickettle:before,
.ss-electrickettle.right:after {
  content: '';
}
.ss-refrigerator:before,
.ss-refrigerator.right:after {
  content: '';
}
.ss-freezer:before,
.ss-freezer.right:after {
  content: '';
}
.ss-utensils:before,
.ss-utensils.right:after {
  content: '🍴';
}
.ss-cookingutensils:before,
.ss-cookingutensils.right:after {
  content: '';
}
.ss-whisk:before,
.ss-whisk.right:after {
  content: '';
}
.ss-pizzacutter:before,
.ss-pizzacutter.right:after {
  content: '';
}
.ss-measuringcup:before,
.ss-measuringcup.right:after {
  content: '';
}
.ss-colander:before,
.ss-colander.right:after {
  content: '';
}
.ss-eggtimer:before,
.ss-eggtimer.right:after {
  content: '';
}
.ss-platter:before,
.ss-platter.right:after {
  content: '';
}
.ss-plates:before,
.ss-plates.right:after {
  content: '';
}
.ss-steamplate:before,
.ss-steamplate.right:after {
  content: '';
}
.ss-cups:before,
.ss-cups.right:after {
  content: '';
}
.ss-steamglass:before,
.ss-steamglass.right:after {
  content: '';
}
.ss-pot:before,
.ss-pot.right:after {
  content: '';
}
.ss-steampot:before,
.ss-steampot.right:after {
  content: '';
}
.ss-chef:before,
.ss-chef.right:after {
  content: '';
}
.ss-weathervane:before,
.ss-weathervane.right:after {
  content: '';
}
.ss-thermometer:before,
.ss-thermometer.right:after {
  content: '';
}
.ss-thermometerup:before,
.ss-thermometerup.right:after {
  content: '';
}
.ss-thermometerdown:before,
.ss-thermometerdown.right:after {
  content: '';
}
.ss-droplet:before,
.ss-droplet.right:after {
  content: '💧';
}
.ss-sunrise:before,
.ss-sunrise.right:after {
  content: '🌅';
}
.ss-sunset:before,
.ss-sunset.right:after {
  content: '🌇';
}
.ss-sun:before,
.ss-sun.right:after {
  content: '☀';
}
.ss-cloud:before,
.ss-cloud.right:after {
  content: '☁';
}
.ss-clouds:before,
.ss-clouds.right:after {
  content: '';
}
.ss-partlycloudy:before,
.ss-partlycloudy.right:after {
  content: '⛅';
}
.ss-rain:before,
.ss-rain.right:after {
  content: '☔';
}
.ss-rainheavy:before,
.ss-rainheavy.right:after {
  content: '';
}
.ss-lightning:before,
.ss-lightning.right:after {
  content: '☇';
}
.ss-thunderstorm:before,
.ss-thunderstorm.right:after {
  content: '⛈';
}
.ss-umbrella:before,
.ss-umbrella.right:after {
  content: '☂';
}
.ss-rainumbrella:before,
.ss-rainumbrella.right:after {
  content: '';
}
.ss-rainbow:before,
.ss-rainbow.right:after {
  content: '🌈';
}
.ss-rainbowclouds:before,
.ss-rainbowclouds.right:after {
  content: '';
}
.ss-fog:before,
.ss-fog.right:after {
  content: '';
}
.ss-wind:before,
.ss-wind.right:after {
  content: '';
}
.ss-tornado:before,
.ss-tornado.right:after {
  content: '';
}
.ss-snowflake:before,
.ss-snowflake.right:after {
  content: '❄';
}
.ss-snowcrystal:before,
.ss-snowcrystal.right:after {
  content: '❆';
}
.ss-lightsnow:before,
.ss-lightsnow.right:after {
  content: '';
}
.ss-snow:before,
.ss-snow.right:after {
  content: '';
}
.ss-heavysnow:before,
.ss-heavysnow.right:after {
  content: '';
}
.ss-hail:before,
.ss-hail.right:after {
  content: '';
}
.ss-crescentmoon:before,
.ss-crescentmoon.right:after {
  content: '🌙';
}
.ss-waxingcrescentmoon:before,
.ss-waxingcrescentmoon.right:after {
  content: '🌒';
}
.ss-firstquartermoon:before,
.ss-firstquartermoon.right:after {
  content: '🌓';
}
.ss-waxinggibbousmoon:before,
.ss-waxinggibbousmoon.right:after {
  content: '🌔';
}
.ss-waninggibbousmoon:before,
.ss-waninggibbousmoon.right:after {
  content: '🌖';
}
.ss-lastquartermoon:before,
.ss-lastquartermoon.right:after {
  content: '🌗';
}
.ss-waningcrescentmoon:before,
.ss-waningcrescentmoon.right:after {
  content: '🌘';
}
.ss-fan:before,
.ss-fan.right:after {
  content: '';
}
.ss-bike:before,
.ss-bike.right:after {
  content: '🚲';
}
.ss-wheelchair:before,
.ss-wheelchair.right:after {
  content: '♿';
}
.ss-briefcase:before,
.ss-briefcase.right:after {
  content: '💼';
}
.ss-hanger:before,
.ss-hanger.right:after {
  content: '';
}
.ss-comb:before,
.ss-comb.right:after {
  content: '';
}
.ss-medicalcross:before,
.ss-medicalcross.right:after {
  content: '';
}
.ss-up:before,
.ss-up.right:after {
  content: '⬆';
}
.ss-upright:before,
.ss-upright.right:after {
  content: '⬈';
}
.ss-right:before,
.ss-right.right:after {
  content: '➡';
}
.ss-downright:before,
.ss-downright.right:after {
  content: '⬊';
}
.ss-down:before,
.ss-down.right:after {
  content: '⬇';
}
.ss-downleft:before,
.ss-downleft.right:after {
  content: '⬋';
}
.ss-left:before,
.ss-left.right:after {
  content: '⬅';
}
.ss-upleft:before,
.ss-upleft.right:after {
  content: '⬉';
}
.ss-navigateup:before,
.ss-navigateup.right:after {
  content: '';
}
.ss-navigateright:before,
.ss-navigateright.right:after {
  content: '▻';
}
.ss-navigatedown:before,
.ss-navigatedown.right:after {
  content: '';
}
.ss-navigateleft:before,
.ss-navigateleft.right:after {
  content: '◅';
}
.ss-retweet:before,
.ss-retweet.right:after {
  content: '';
}
.ss-share:before,
.ss-share.right:after {
  content: '';
}


/**
 * Social
 */
@font-face {
  font-family: "SSSocialRegular";
  src: url('../fonts/ss-social-regular.eot');
  src: url('../fonts/ss-social-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/ss-social-regular.woff') format('woff'),
       url('../fonts/ss-social-regular.ttf')  format('truetype'),
       url('../fonts/ss-social-regular.svg#SSSocialRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SSSocialCircle";
  src: url('../fonts/ss-social-circle.eot');
  src: url('../fonts/ss-social-circle.eot?#iefix') format('embedded-opentype'),
       url('../fonts/ss-social-circle.woff') format('woff'),
       url('../fonts/ss-social-circle.ttf')  format('truetype'),
       url('../fonts/ss-social-circle.svg#SSSocialCircle') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"]{-ms-zoom: 1;}

.ss-icon, .ss-icon.ss-social-regular,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-social-regular:before, [class*=" ss-"].ss-social-regular:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-social-regular.right:after, [class*=" ss-"].ss-social-regular.right:after {
  font-family: "SSSocialRegular";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.ss-icon, .ss-icon.ss-social-circle,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-social-circle:before, [class*=" ss-"].ss-social-circle:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-social-circle.right:after, [class*=" ss-"].ss-social-circle.right:after {
  font-family: "SSSocialCircle";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

[class^="ss-"].right:before,
[class*=" ss-"].right:before{
	display:none;content:'';
}

.ss-facebook:before,
.ss-facebook.right:after {
  content: '';
}
.ss-twitter:before,
.ss-twitter.right:after {
  content: '';
}
.ss-linkedin:before,
.ss-linkedin.right:after {
  content: '';
}
.ss-googleplus:before,
.ss-googleplus.right:after {
  content: '';
}
.ss-appdotnet:before,
.ss-appdotnet.right:after {
  content: '';
}
.ss-zerply:before,
.ss-zerply.right:after {
  content: '';
}
.ss-reddit:before,
.ss-reddit.right:after {
  content: '';
}
.ss-steam:before,
.ss-steam.right:after {
  content: '';
}
.ss-tumblr:before,
.ss-tumblr.right:after {
  content: '';
}
.ss-wordpress:before,
.ss-wordpress.right:after {
  content: '';
}
.ss-blogger:before,
.ss-blogger.right:after {
  content: '';
}
.ss-posterous:before,
.ss-posterous.right:after {
  content: '';
}
.ss-quora:before,
.ss-quora.right:after {
  content: '';
}
.ss-youtube:before,
.ss-youtube.right:after {
  content: '';
}
.ss-vimeo:before,
.ss-vimeo.right:after {
  content: '';
}
.ss-vine:before,
.ss-vine.right:after {
  content: '';
}
.ss-letterboxd:before,
.ss-letterboxd.right:after {
  content: '';
}
.ss-flickr:before,
.ss-flickr.right:after {
  content: '';
}
.ss-instagram:before,
.ss-instagram.right:after {
  content: '';
}
.ss-500px:before,
.ss-500px.right:after {
  content: '';
}
.ss-etsy:before,
.ss-etsy.right:after {
  content: '';
}
.ss-pinterest:before,
.ss-pinterest.right:after {
  content: '';
}
.ss-svpply:before,
.ss-svpply.right:after {
  content: '';
}
.ss-readmill:before,
.ss-readmill.right:after {
  content: '';
}
.ss-dropbox:before,
.ss-dropbox.right:after {
  content: '';
}
.ss-pinboard:before,
.ss-pinboard.right:after {
  content: '';
}
.ss-delicious:before,
.ss-delicious.right:after {
  content: '';
}
.ss-dribbble:before,
.ss-dribbble.right:after {
  content: '';
}
.ss-behance:before,
.ss-behance.right:after {
  content: '';
}
.ss-github:before,
.ss-github.right:after {
  content: '';
}
.ss-octocat:before,
.ss-octocat.right:after {
  content: '';
}
.ss-stackoverflow:before,
.ss-stackoverflow.right:after {
  content: '';
}
.ss-paypal:before,
.ss-paypal.right:after {
  content: '';
}
.ss-kickstarter:before,
.ss-kickstarter.right:after {
  content: '';
}
.ss-foursquare:before,
.ss-foursquare.right:after {
  content: '';
}
.ss-yelp:before,
.ss-yelp.right:after {
  content: '';
}
.ss-skype:before,
.ss-skype.right:after {
  content: '';
}
.ss-rdio:before,
.ss-rdio.right:after {
  content: '';
}
.ss-spotify:before,
.ss-spotify.right:after {
  content: '';
}
.ss-lastfm:before,
.ss-lastfm.right:after {
  content: '';
}
.ss-soundcloud:before,
.ss-soundcloud.right:after {
  content: '';
}
.ss-link:before,
.ss-link.right:after {
  content: '🔗';
}
.ss-phone:before,
.ss-phone.right:after {
  content: '📞';
}
.ss-mail:before,
.ss-mail.right:after {
  content: '✉';
}
.ss-like:before,
.ss-like.right:after {
  content: '👍';
}
.ss-rss:before,
.ss-rss.right:after {
  content: '';
}
.ss-share:before,
.ss-share.right:after {
  content: '';
}
.ss-apple:before,
.ss-apple.right:after {
  content: '';
}
.ss-microsoft:before,
.ss-microsoft.right:after {
  content: '';
}
.ss-windows:before,
.ss-windows.right:after {
  content: '';
}
.ss-android:before,
.ss-android.right:after {
  content: '';
}
.ss-blackberry:before,
.ss-blackberry.right:after {
  content: '';
}

/* Legacy */
.ss-fivehundredpx:before,
.ss-fivehundredpx.right:after{
	content:'';
}