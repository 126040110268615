.b-card-slider {
	@include media-breakpoint-down(xs) {
		padding: 3rem 0;
	}
}
.cards-wrapper {
	@include media-breakpoint-down(xs) {
		margin-bottom: 3rem;
	}
}

/**
 * Card Slider
 */
.card-slider-carousel {
	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
		padding-bottom: 4rem;
	}
	.slick-slide {
		@include media-breakpoint-up(sm) {
			padding-bottom: 3rem;
		}
		> div {
			@include media-breakpoint-up(sm) {
				padding: 0 1.5rem;
			}
		}
	}
	.slick-arrow {
		bottom: -40px;
		top: auto;
		&:before {
			color: $gray-dark;
		}
	}
	.slick-list {
		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}
	.slick-track {
		@include media-breakpoint-up(sm) {
			display: flex;
		}
		&:before {
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
		.slick-slide {
			@include media-breakpoint-up(sm) {
				display: flex;
				flex: 1 0 auto;
				flex-direction: column;
			}
			> div {
				@include media-breakpoint-up(sm) {
					height: 100%;
				}
				> div {
					@include media-breakpoint-up(sm) {
						height: 100%;
					}
				}
			}
			.card {
				position: relative;
				img {
					// @include media-breakpoint-down(xs) {
					// 	max-width: 110px;
					// }
				}
			}
		}
	}
	.slick-next {
		right: -10px;
		@include media-breakpoint-up(sm) {
			right: 0;
		}
	}
	.slick-prev {
		left: -10px;
		@include media-breakpoint-up(sm) {
			left: 0;
		}
	}
	.slick-next,
	.slick-prev {
		&.slick-disabled {
			&:before {
				opacity: 0;
			}
		}
	}
	.slick-dots {
		bottom: 0px;
		@include media-breakpoint-up(sm) {
			bottom: -20px;
		}
		li {
			button {
				border-color: $gray-dark;
				&:hover,
				&:focus {
					border-color: $gray-dark;
					background-color: lighten( $gray-dark, 30% );
				}
			}
			&.slick-active {
				button {
					background-color: $gray-dark;
					&:hover,
					&:focus {
						border-color: $gray-dark;
						background-color: $gray-dark;
					}
				}
			}
		}
	}
	/**
	 * Remote Slider
	 */
	&.card-slider-remote {
		.slick-dots {
			bottom: 10px;
			@include media-breakpoint-up(sm) {
				bottom: -30px;
			}
		}
		.slick-arrow {
			bottom: -10px;
			@include media-breakpoint-up(sm) {
				bottom: -50px;
			}
		}
	}
	/**
	 * Local Slider
	 */
	&.card-slider-local {
		padding: 0 1rem;
		@include media-breakpoint-up(sm) {
			padding: 0 5rem;
		}
		.big-text {
			.card-container {
				max-width: 400px;
				height: 240px;
				margin: 0 auto;
				width: 100%;
				.card {
					min-height: 100%;
					position: relative;
					a {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate( -50%, -50% );
						width: 100%;
					}
				}
			}
		}
		.card-container {
			padding: 1.5rem;
			.card {
				border: 9px solid $white;
				display: flex;
				flex: 1;
				justify-content: center;
				align-items: center;
				text-align: center;
				min-height: 20vw;
				position: relative;
				&:before {
				  content: "";
				  position: absolute;
				  top: -9px;
				  right: -9px;
				  border-width: 0 40px 40px 0;
				  border-style: solid;
				  border-color: $orange $orange $white $white;
				  display: block;
				  width: 0;
				  z-index: 2;
				}
				a {
					display: flex;
					color: $white;
					flex: 1;
					font-size: 2.2rem;
					font-weight: 700;
					align-items: center;
					justify-content: center;
					padding: 2rem;
					text-transform: uppercase;
					@include media-breakpoint-up(sm) {
						font-size: 2.8rem;
					}
				}
			}
			&.bg-blue .card {
				&:before {
					border-color: $blue $blue $white $white;
				}
			}
			&.bg-orange .card {
				&:before {
					border-color: $orange $orange $white $white;
				}
			}
			&.bg-green .card {
				&:before {
					border-color: $green $green $white $white;
				}
			}
			&.bg-red .card {
				&:before {
					border-color: $red $red $white $white;
				}
			}
			&.bg-purple .card {
				&:before {
					border-color: $purple $purple $white $white;
				}
			}
		}
		.slick-arrow {
			top: 47%;
			@include media-breakpoint-up(sm) {
				top: 57%;
			}
		}
	}
}