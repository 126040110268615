.b-squared-blocks-intro {
	@include media-breakpoint-down(xs) {
		margin: 0 -15px;		
	}
	.squared-block-image {
		// margin: 0 -15px;
	}
	.squared-block-text {
		@include media-breakpoint-down(xs) {
			// margin: 0 -15px;
			padding: 3rem 0;
		}
	}
}