/**
 * Inverse
 */
.inverse {
  color: $white;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}
input {
  @include placeholder {
    font-style: normal !important;
  }
  .menu-item-search & {
    @include placeholder {
      color: $gray;
      font-style: italic !important;
      font-size: 1.4rem;
      $font-family:  "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    }
  }
}
#gform_2 input {
  border: none;
  @include placeholder {
    font-style: italic !important;
  }
}
