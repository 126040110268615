.convention-app {
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #7E2BA4;
  @include media-breakpoint-up(md) {
    min-height: 760px;
  }
  .download-links {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    a {
      display: inline-block;
      margin: 0 1.5rem;
      @include media-breakpoint-down(xs) {
        margin-bottom: .6rem;
      }
    }
  }
  .column {
    img {
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        margin: 0 auto;
      }
    }
  }
}

/**
 * Pattern Bg
 */
.pattern-bg {
  @include media-breakpoint-up(md) {
    padding: 0 7rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    color: $gray;
    font-size: 1.6rem;
  }
  .bg-white {
    @include media-breakpoint-up(md) {
      padding: 2rem 5rem;
    }
  }
  img {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
  p {
    line-height: 1.4;
  }
  .flex-row {
    > div {
      @include media-breakpoint-up(md) {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
      }
    }
    > div:first-child {
      @include media-breakpoint-up(sm) {
        padding-right: 5px;
      }
    }
    > div:last-child {
      @include media-breakpoint-up(sm) {
        padding-left: 5px;
      } 
    }
  }
}


/**
 * Custom Classes
 */
.want-to {
  .row {
    @include media-breakpoint-up(md) {
      display: flex;
    }
    .column {
      @include media-breakpoint-up(md) {
        flex: 1 1 33.3333%;
      }
      .note {
        @include media-breakpoint-up(md) {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0;
          align-content: center;
        }
        a {
          @include media-breakpoint-up(md) {
            display: block;
            position: relative;
            padding: 9rem 2rem 10rem;
            width: 100%;
            z-index: 100;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.space-between {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
  a {
    text-transform: uppercase;
    span {
      font-size: 1.2rem;
      @include media-breakpoint-up(sm) {
        margin-left: 1rem;
      }
    }
  }
}

.note {
  background: $white;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  text-align:center;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 8px solid #e40046;
    z-index:1;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 25px 25px 0;
    border-style: solid;
    border-color: #fff #fff #e40046 #e40046;
    /* Firefox 3.0 damage limitation */
    display: block; width: 0;
    z-index: 2;
  }
  &.flat {  
    &:after {
      border: none;
      border-bottom: 5px solid #8edd65 !important;
    }
  }
  .gradient &:before {
    border-width: 0 25px 25px 0;
    border-color: #26a3da;
  }
  
}

/**
 * Home Page Styles
 */
.home {
  .slick-carousel {
    h2 {
      font-size: 4rem;
      line-height: 1.2;
      padding-bottom: 4.5rem;
      margin-bottom: 2.5rem;
      &:after {
        background-color: $white;
      }
    }
    img.img-fluid {
      max-width: none;
    }
  }
}

// Custom Block Styles
.b-speakers,
.b-content {
  &.speakers-detail {
    .note {
      padding: 3rem 3rem 2rem;
    }
  }
  .note {
    padding: 3rem 2rem;
    @include media-breakpoint-up(sm) {
      padding: 3rem 3rem;
    }
    @include media-breakpoint-up(md) {
      padding: 5rem 5rem;
    }
    @include media-breakpoint-down(xs) {
      min-height: 250px;
      margin-bottom: 2.2rem;
    }
    h4 {
      font-size: 20px;
      margin-bottom: 2.5rem;
    }

    p {
      font-size: 14px;
      line-height: 1.38;
      font-weight: 200;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
    img {
      margin: 0 auto 2rem;
      @include media-breakpoint-down(sm) {
        max-width: 140px;
      }
    }
  }
}

.b-content {
  .note {
    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
    }
  }
}

/**
 * Inner Navigation
 */
.inner-navigation {
  h3 {
    font-size: 2rem;
  }
  .nav-stacked {
    padding-left: 0;
    li {
      &.active {
        a {
          color: #9B26B6;
          &:hover,
          &:focus {
            cursor: default;
          }
        }
      }
      a {
        color: $gray;
        display: inline-block;
        margin-bottom: 2rem;
        padding: 0;
        &:hover,
        &:focus {
          background: none;
          color: #9B26B6;
        }
      }
    }
  }
}

/**
 * Content Grid
 */
.content-grid {
  h2 {
    margin-bottom: 6rem;
  }
  .card {
    background-color: $white;
    margin-bottom: 6rem;
    .card-block {
      padding: 2.5rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      @extend .subtitle;
      margin-top: -1rem;
      margin-bottom: 2rem;
      font-size: 1.7rem;
      font-style: italic;
    }
  }
  .card-img-top {
    max-width: 100%;
    height: auto;
  }
}

/**
 * Juicer
 */
.j-stacker-wrapper {
  .j-stacker {
    .j-stack {
      &:first-child {
        li {
          &:last-child {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }
  }
}

/**
 * Carousel
 */
.carousel-overlay {
  background-color: rgba( 0,0,0,.3 );
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.carousel-item-text {
  color: $white;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate( 0, -50% );
  @include media-breakpoint-up(sm) {
    width: 100%;
  }
}

/**
 * Group Card
 */
.group-card-container {
  border: 9px solid $black;
  min-height: 17.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 30px;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: $white $white $black $black;
    display: block;
    width: 0;
    z-index: 2;
  }
  .group-card {
    border-left-width: 14px;
    border-left-style: solid;
    display: flex;
    flex: 1;
    a {
      align-items: center;
      color: #222222;
      display: flex;
      flex: 1;
      font-family: 'norwesterregular';
      font-size: 2.6rem;
      justify-content: center;
      text-transform: uppercase;
      .group-title {
        display: inline-block;
        text-decoration: underline;
      }
    }
  }
}

/**
 * Policy Table
 */
#state-filter {
  @extend #tribe-events-bar;
  position: relative;
}
#policy-search {
  @extend #tribe-bar-form;
}
.policy-table {
  th:not(:last-child) {
    border-right: 2px solid #efeded;
  }
  thead {
    tr {
      th {
        background-color: $green;
        min-width: 200px;
        padding: 1.5rem 2rem;
        a {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
  tbody {
    td:not(:last-child) {
      border-right: 2px solid #efeded;
    }
    tr {
      td {
        border-bottom: none;
        border-top: none;
        padding: 1.5rem 2rem;
        a {
          color: $blue;
        }
      }
    }
  }
}

/**
 * Micro Landing
 */
 $levels: (elementary, $purple),
          (middle, $red),
          (secondary, $orange),
          (college, $blue);
@each $level, $color in $levels {
  .#{$level} {
    .banner {
      border-bottom-color: #{$color};
    }
    .secondary-navigation-wrap {
      border-right-color: #{$color};
    }
    .highlight:after {
      border-bottom-color: #{$color};
    }
    [class*="card-columns-border-bottom-"],
    [class*="card-border-bottom-"] {
      border-color: #{$color};
    }
    [class*="card-image-border-"] {
      .img-holder {
        border-color: #{$color};
      }
    }
    .b-content {
      [class*="btn-outline-white"] {
        border-color: #{$color};
        span {
          color: #{$color};
        }
        &:after {
          color: $white;
        }
        &:hover,
        &:focus {
          background-color: #{$color};
          border-color: #{$color};
          span {
            color: $white;
          }
        }
      }
      [class*="btn-outline-"] {
        border-color: #{$color};
        span {
          color: #{$color};
        }
        &:hover,
        &:focus {
          background-color: #{$color};
          border-color: #{$color};
        }
      }
    }
    .sub-footer {
      [class*="btn-outline-white"] {
        &:hover,
        &:focus {
          span {
            color: #{$color};
          }
        }
        &:after {
          color: #{$color};
        }
      }
    }
    .btn:not([class*="btn-outline-"]) {
      background-color: #{$color};
      border-color: #{$color};
    }
    .card-event {
      border-bottom-color: #{$color} !important;
    }
    .tribe-event-url,
    .event-year {
      color: #{$color} !important;
    }
    .sub-footer {
      background-color: #{$color} !important;
    }
  }
}