.single-statement,
.single-report {
	background-color: $gray-lighter;
	article {
		@include media-breakpoint-up(sm) {
			padding: 3rem;
		}
		header {
			margin-bottom: 3rem;
		}
	}
}