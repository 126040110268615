@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */
.slick-list {
	.slick-loading & {
		background: #fff url("../images/ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */
.slick-prev,
.slick-next {
	background: transparent;
	border: none;
	color: transparent;
	cursor: pointer;
	display: block;
	font-size: 0px;
	height: 32px;
	line-height: 0px;
	margin-top: -10px\9; /*lte IE 8*/
	padding: 0;
	position: absolute;
	top: 47%;
	transform: translate(0, -50%);
	width: 20px;
	outline: none;
	z-index: 999;
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
}

.slick-prev:before, 
.slick-next:before {
	color: $white;
	font-family: $slick-font-family;
	font-size: 36px;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: 30px;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

.slick-next {
	right: 30px;
	[dir="rtl"] & {
		left: 0px;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

/* Dots */
.slick-slider {
	margin-bottom: 0;
}

.slick-dots {
	bottom: 20px;
	display: block;
	list-style: none;
	margin-bottom: 0;
	margin-top: 20px;
	padding: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	line-height: 22px;
	@include media-breakpoint-up(md) {
		bottom: 30px;
	}
	li {
		position: relative;
		display: inline-block;
		height: 10px;
		width: 10px;
		margin: 0 4px;
		padding: 0;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			margin: 0 4px;
			height: 13px;
			width: 13px;
		}
		&.slick-active {
			button {
				background-color: $brand-primary;
				&:focus,
				&:hover {
					background-color: $brand-primary;
					cursor: default;	
					border-color: $white;
				}
			}
		}
		button {
			border: 3px solid $white;
			border-radius: 50%;
			background: transparent;
			display: block;
			height: 10px;
			width: 10px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			@include media-breakpoint-up(md) {
				height: 13px;
				width: 13px;
			}
			&:hover, 
			&:focus {
				border-color: $gray-lighter;
			}
		}
	}
}

/* Position */
.slide-content {
	position: absolute;
	&.top-left {
		top: calc( #{$navbar-height} + 30px );
		left: 2.5vw;
	}
	&.bottom-left {
		bottom: 2vw;
		left: 2.5vw;
	}
	&.top-right {
		top: calc( #{$navbar-height} + 30px );
		right: 2.5vw;
	}
	&.bottom-right {
		bottom: 2vw;
		right: 2.5vw;
	}
}