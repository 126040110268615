.b-teaching-stories {
	.column {
		.card {
			display: block;
		}
	}
	#tribe-events-content-wrapper {
		@include media-breakpoint-down(xs) {
			padding: 4rem 0;
		}
	}
	h2.title {
		margin-bottom: 2rem;
	}
}