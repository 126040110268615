.faq-accordion {
	.card {
		background-color: $white;
		margin-bottom: 1rem;
		padding: 0;
		.card-header {
			a {
				color: $text-color;
				display: flex;
				font-size: 2rem;
				padding: 2rem;
				position: relative;
				@include media-breakpoint-up(md) {
					padding-right: 25%;
				}
				i {
					color: $brand-secondary;
					font-size: 1.6rem;
					position: absolute;
					right: 2rem;
					top: 50%;
					transform: translate( 0, -50% ); 
					&.ss-up {
						color: $brand-primary;
					}
				}
			}
		}
		.card-block {
			padding: 0 2rem 2rem;
		}
	}
}