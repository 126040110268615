.page-template-template-conference {
	/**
	 * Section Header
	 */
	.conference-page-header {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		position: relative;
		@include media-breakpoint-down(sm) {
			background-size: contain;
		}
		> .container {
			position: relative;
			z-index: 20;
			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}
		.flex-row {
			@include media-breakpoint-up(sm) {
				display: flex;
				z-index: 100;
			}
			.col-6 {
				@include media-breakpoint-up(sm) {
					width: 50%;
				}
			}
		}
		.conference-title {
			display: flex;
			flex-direction: column;
		  justify-content: center;
		  text-align: center;
		  @include media-breakpoint-down(md) {
		  	position: relative;
		  	padding: 0 0;
		  }
		  .conference-header-image {
			  img {
			  	@include media-breakpoint-down(sm) {
			  		width: 100%;
			  	}
			  }
			}
		  .conference-header-text {
		  	@include media-breakpoint-down(md) {
			  	position: absolute;
			  	width: 100%;
			  	top: 50%;
			  	left: 50%;
			  	transform: translate( -50%, -50% );
			  }
		  }
		  h1 {
		  	color: $white;
		  	font-weight: 700;
		  	font-size: 2.6rem;
		  	line-height: 1;
		  	margin-bottom: 0;
		  	@include media-breakpoint-up(md) {
		  		font-size: 4.8rem;
		  	}
		  }
		  span {
		  	font-size: 1.8rem;
		  	@include media-breakpoint-up(md) {
		  		font-size: 3.2rem;
		  	}
		  }
		}
		.conference-banner {
			background-color: $white;
			padding: 3rem 2rem 4rem;
			@include media-breakpoint-up(md) {
				padding: 6rem 6rem 4rem;
				margin: 2.5rem 0;
			}
			h3 {
				font-size: 2.8rem;
				margin-bottom: .5rem;
			}
			h4 {
				font-size: 2rem;
				margin-bottom: 2rem;
			}
			.btn {
				margin-bottom: 2rem;
				margin-top: 2rem;
			}
		}
	}

	/**
	 * Child Navigation
	 */
	.child-nav-wrap {
		background-color: $gray-dark;
		.child-nav {
			display: flex;
			flex: 1;
			justify-content: space-around;
			margin-bottom: 0;
			padding: 0;
			width: 100%;
			li {
				flex: 1;
				display: flex;
				// align-items: flex-start;
				list-style: none;
				text-align: center;
				&.active {
					a {
						background-color: $brand-secondary;
						&:focus,
						&:hover {
							background-color: $brand-secondary;
							cursor: default;
						}
					}
				}
				a {
					color: $white;
					font-size: 18px;
					display: flex;
					flex-direction: column;
					flex: 1;
					line-height: 1.3;
					padding: 30px 10px 21px;
					width: 100%;
					&:hover,
					&:focus {
						background-color: $gray;
					}
					span {
						font-family: "SS BlackTie-Regular";
						font-size: 2.5rem;
						display: block;
						margin-bottom: .5rem;
					}
				}
			}
		}
		.mobile-child-nav {
			margin-bottom: 0;
			padding: 0;
			li.dropdown {
				a {
					border-bottom: 1px solid $black;
					color: $white;
					display: block;
					padding: 2rem;
				}
				.dropdown-menu {
					background-color: $gray-dark;
					border-radius: 0;
					border: none;
					margin: 0;
					padding: 0;
					width: 100%;
					span {
						display: inline-block;
						vertical-align: middle;
						width: 25px;
					}
				}
			}
		}
	}

	/**
	 * Sub Page Title
	 */
	.subpage-title {
		background-color: $violet;
		padding: 20px 0;
		h1 {
			color: $white;
			font-size: 2.8rem;
			margin: 0;
		}
		.menu {
  		font-size: 14px;
			ul {
				@include clearfix;
				padding: 0;
				margin-bottom: 0;
				li {
					float: left;
					list-style: none;
					line-height: 35px;
					margin-left: 3rem;
					&:first-child {
						margin-left: 0;
					}
					a {
						color: $white;
					}
				}
			}
		}
	}
}