.subnavigation {
	@include media-breakpoint-down(xs) {
		padding: 3rem 0;
	}
	h2  {
		font-size: 2rem;
		font-weight: 400;
		margin: 0 0 3rem;
		// text-transform: uppercase;
		a {
			color: $text-color;
			line-height: 1.7;
			span {
				border-bottom: 8px solid $brand-primary;
			}
		}
	}
	.back-link {
		font-weight: 500;
		margin-bottom: 2rem;
		a {
			color: $gray;
			font-size: 1.4rem;
		}
	}
	ul {
		li {
			line-height: 1.3;
			margin-bottom: 1rem;
			&.list-separator {
				border-top: 1px dashed #cfcfcf;
				padding-top: 2rem;
				margin-top: 2rem;
			}
			&.current_menu_item {
				a {
					color: $gray-light;
				}
			}
			a {
				color: $text-color;
				font-size: 1.4rem;
			}
			a[href*="facebook.com"]::before,
			a[href*="linkedin.com"]::before,
			a[href*="pinterest.com"]::before,
			a[href*="twitter.com"]::before,
			a[href*="youtube.com"]::before, 
			a[href*="instagram.com"]::before { 
				font-family: 'SSSocialCircle';
				margin-right: 1rem;
				vertical-align: middle;
			}
			a[href*="facebook.com"]::before {
				content: '\F610';
			}
			a[href*="linkedin.com"]::before { 
				content: '\F612';
			}
			a[href*="twitter.com"]::before { 
				content: '\F611';
			}
			a[href*="instagram.com"]::before { 
				content: '\F641'; 
			}
			a[href*="medium.com"]::before { 
				content: '\F614';
			}
			a[href*="pinterest.com"]::before { 
				content: '\F650';
			}
			a[href*="youtube.com"]::before { 
				content: '\F630';
			}
		}
	}
}

.subnavigation-dropdown {
	margin: 0 -15px;
	background-color: $gray-lighter;
	padding: 0;
	margin-bottom: 3rem;
	a.subnav-dropdown-toggle {
		display: block;
		height: 75px;
		padding: 2rem 3rem;
		font-size: 2rem;
		color: $gray-dark;
		position: relative;
		span.subnav-icon {
			font-family: "SS BlackTie-Bold";
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translate(0, -50% );
			&:before {
				font-family: "SS BlackTie-Bold";
			}
		}
		&:after {
			display: none;
		}
	}
	span.subnav-title {
		border-bottom: 8px solid #8edd65;
	}
	.dropdown-menu {
		background-color: $gray-lighter;
		position: absolute;
		top: 75px;
		width: 100%;
		border: 0;
		margin: 0;
		padding: 0 0 2rem;
		li {
			a {
				padding: .8rem 3rem;
			}
		}
	}
}

.sidebar-primary {
	.content {
		@include clearfix();
	}
	main.main {
		float: left;
		padding: 0 0;
		@include media-breakpoint-up(sm) {
			padding: 0 1.5rem;
		}
	}
	aside.sidebar {
		float: left;
		padding: 0 0 3rem;
		@include media-breakpoint-up(sm) {
			padding-left: 3rem;
			padding-right: 0;
		}
	}
	.widget_categories {
		background-color: $white;
		padding: 2rem;
		margin-top: 0rem;
		margin-bottom: 3rem;
		ul {
			padding-left: 0;
			list-style: none;
			li {
				list-style: none;
				a {
					font-size: 1.4rem;
				}
			}
		}
	}
	.widget_sp_image {
		img {
			height: auto;
		}
	}
}
