.b-categories {
	.card {
		.card-block {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 190px;
			padding: 0;
			a {
				color: $text-color;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-transform: uppercase;
				padding: 3rem 2rem;
			}
			h3 {
				margin-bottom: 0;
				font-size: 2.2rem;
				font-weight: 700;
				text-align: center;
			}
		}
	}
}	