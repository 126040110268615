.search-wrap {
	.dropdown {
		margin-bottom: 4rem;
	}
	button {
		border-radius: 0;
		margin-bottom: 0rem;
		text-align: left;
		&:after {
			content: '\F501';
			color: #afafaf;
			font-size: 1.8rem;
			font-family: SSGizmo;
			position: absolute;
			opacity: 1;
			right: 1rem;
			top: 50%;
			z-index: 100;
		}
	}
	.page-header {
		border-bottom: none;
		margin-bottom: 1rem;
	}
	article {
		font-size: 1.6rem;
		margin-bottom: 4rem;
		h2 {
			margin-bottom: .5rem;
			font-size: 1.6rem;
		}
	}
}

.b-search_result_list__item_breadcrumbs {
	a {
		color: $text-color;
	}
}