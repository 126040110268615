.page-header {
	margin: 0;
}

.page-header-sidebar {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: calc( 31rem + 4.16666666vw );
	display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 4rem 0;
  @include media-breakpoint-up(lg) {
  	height: calc( 31rem + 4.16666666vw );
  }
  &.page-header-centered {
  	min-height: 30rem;
  	padding-bottom: 0;
  	h1 {
  		@include media-breakpoint-up(sm) {
  			line-height: 1.2;
	  		font-size: 4rem;
	  		font-weight: 700;
	  		margin-bottom: 0;
	  	}
  	}
  }
  .img-overlay {
  	background-color: rgba( 0,0,0,.4 );
  	position: absolute;
  	left: 0;
  	top: 0;
  	width: 100%;
  	height: 100%;
  }
  h1 {
  	font-size: 4rem;
  	&.featured-title {
  		margin-bottom: 3rem;
  		&:after {
  			background-color: $white;
  		}
  	}
  }
  p {
  	font-size: 2.4rem;
  }
}
.sidebar-template-intro {
	.block {
		padding: 0;
	}
	.card-slider-carousel {
		@include media-breakpoint-up(sm) {
			padding-top: 5rem;
		}
	}
}

.sidebar-left-square {
	img {
		@include media-breakpoint-up(md) {
			width: 100%;
		}
	}
}
.sidebar-right-square {
	display: flex;
	flex-direction: column;
	justify-content: center;
	> div {
		padding: 0 3rem;
	}
	h2 {
		margin-bottom: 3rem;
	}
	.btn {
		margin-top: 3.5rem;
	}
	&.multiple-buttons {
		.btn {
			&:first-child {
				@include media-breakpoint-up(md) {
					margin-right: 1rem;	
				}
			}
			&:last-child {
				@include media-breakpoint-up(md) {
					margin-left: 1rem;
				}
			}
		}
	}
}

/**
 * Current Journal Template
 */
.page-template-template-currentjournal {
	.sidebar-template-intro {
		h1 {
			font-size: 2rem;
			font-weight: 600;
		}
	}
	h2 {
		font-size: 1.6rem;
		margin-bottom: 2.5rem;
	}
	.issue-wrapper {
		margin-bottom: 3rem;
		img {
			border: 3px solid #D8D8D8;
		}
	}
	.journal-story {
		font-size: 1.4rem;
		line-height: 1.65;
		margin-bottom: 2.5rem;
		a {
			color: $blue;
			font-weight: 500;
		}
	}
	.journal-story-title,
	.journal-story-subscription {
		margin-bottom: 0.5rem;
	}
	.journal-story-author {
		color: $gray;
	}
	.journal-abstract {
		color: $gray-light;
		font-style: italic;
	}
}

/**
 * Home
 */
.home {
	#accordion-id {
		button.slick-arrow {
			@include media-breakpoint-down(xs) {
				display: none !important;
			}
		}
		.slick-dots {
			@include media-breakpoint-down(xs) {
				bottom: 5rem;
			}
			li {
				@include media-breakpoint-down(xs) {
					margin: 0 .8rem;
				}	
			}
		}
	}
}
