.b-policy-reports, .b-position-statements {
  @include media-breakpoint-down(md) {
    padding: 3rem 0;
  }
  .publications-filters {
    margin-bottom: 3rem;
    button {
      border-radius: 0;
      text-align: left;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
        margin-top: 3rem;
        max-width: none;
        width: 100%;
      }
      &:after {
        border: none;
        content: '\F501';
        color: $gray-light;
        font-size: 1.8rem;
        font-family: "SSGizmo";
        position: absolute;
        opacity: 1;
        right: 1rem;
        top: 50%;
        z-index: 100;
        width: auto;
      }
    }
    .dropdown {
      ul {
        border-radius: 0;
        margin-top: 0;
      }
    }
    .text-right {
      &:after {
        color: $gray-light;
        content: '\1F50E';
        font-family: "SSGizmo";
        position: absolute;
        right: 30px;
        top: 55%;
        transform: translate(0,-50%);
        @include media-breakpoint-down(md) {
          top: 35%;
        }
      }
    }
    #publications-filter-search {
      border: 2px solid #ccc;
      height: 44px;
      padding: 10px 12px;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
        max-width: none;
        width: 100%;
      }
      @include media-breakpoint-up(sm) {
        min-width: 370px;
      }
    }
  }
  .table {
   > thead > tr > th {
      background-color: #8edd65;
      color: black;
      text-decoration: underline;
      border-right: solid 4px white;
      padding: 14px;
      &:last-child {
        border: 0;
      }
    }
    >tbody > tr > td {
      padding: 14px;
      border: 0 !important;
      border-right: solid 4px #efeded !important;
      &:last-child {
        border: 0 !important;
      }    
    }
  
    &.table-striped>tbody>tr:nth-of-type(even) {
      background-color: #efeded;
    }
    
  }


}