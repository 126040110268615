.b-full-width-pattern {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.column {
		
	}
	.full-width-pattern-text {
		@include media-breakpoint-down(xs) {
			padding: 1.5rem;
		}
		> div {
			@include media-breakpoint-down(xs) {
				padding: 3rem 1.5rem 1.5rem;
			}
		}
		a.btn {
			@include media-breakpoint-down(xs) {
				margin-bottom: 1.5rem;
			}
		}
	}
	.full-width-pattern-image {
		@include media-breakpoint-down(xs) {
			margin: 0 -15px;
		}
	}
}