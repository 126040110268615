.b-link-list {
	@include media-breakpoint-down(xs) {
		padding: 3rem 0;
	}
	ul {
		li {
			line-height: 1.75;
			font-size: 1.6rem;
			a {
				font-weight: 400;
				font-size: 1.6rem;
			}
		}
	}
}