blockquote {
	border-left: 5px solid $black;
	margin: 5rem 0rem 5rem 7rem;
	font-size: 2rem;
	padding: .5rem 0 0 3rem;
	.b-content & {
		p {
			line-height: 1.5;
		}
	}
}