.card-header {
	padding: 0;
	border: none;
	background-color: transparent;
}
.card {
	background: none;
	border: none;
	border-radius: 0;
	position: relative;
	@include media-breakpoint-down(sm) {
		margin-bottom: 3rem;
	}
	img {
		@include transition( all .5s ease-in-out );
	}
	img.full {
		@include media-breakpoint-up(lg) {
			width: 100%;
		}
	}
	a.block-link {
		display: block;
		h3,
		p {
			color: $text-color;
		}
		&:hover,
		&:focus {
			.img-holder {
				img {
					transform: scale(1.05);
				}
			}
		}
	}
	.img-holder {
		overflow: hidden;
	}
	.card-block {
		// padding: 2rem .5rem 2rem;
		&.card-img-left {
			padding-bottom: 0;
		}
	}
}
.column {
	@include media-breakpoint-up(sm) {
		// flex: 0 0 auto;
		display: block;
		// flex-direction: column;
	}
	.card {
		@include media-breakpoint-up(sm) {
			display: block;
			flex-direction: column;
			flex: 0 0 auto;
		}	
	}
}

.card-inverse {
	color: $white;
}
.card-img-overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 0;
}

/**
 * Cards
 */
[class*="card-border-bottom-"] {
	border-bottom-width: 7px;
	border-bottom-style: solid;
	background-color: $white;
	height: 100%;
	h3 {
		font-size: 2rem;
		&.featured-title {
			&:after {
				background-color: $gray-dark;
				width: 64px;
			}
		}
	}
	.card-block {
		padding: 3rem 2rem;
		font-size: 1.4rem;
	}
}
/**
 * Card Border Bottom 
 */
[class*="card-columns-border-bottom-"] {
	border-bottom-width: 7px;
	border-bottom-style: solid;
	background-color: $white;
	height: 100%;
	padding: 0 15px;
	h3 {
		font-size: 2rem;
	}
	.card-block {
		padding: 2rem .5rem;
		font-size: 1.4rem;
	}
}
/**
 * Card Border Bottom 
 */
[class*="card-image-border-"] {
	// height: 100%;
	padding: 0;
	.img-holder {
		border-bottom-width: 7px;
		border-bottom-style: solid;
	}
	h3 {
		font-size: 2rem;
	}
	.card-block {
		padding: 2rem 0;
		font-size: 1.4rem;
	}
}

$colors: (red, '#e40046'),
         (blue, '#05c3de'),
         (orange, '#ff8f1c'),
         (purple, '#9B26B6'),
         (green, '#8EDD65');

@each $label, $color in $colors {
  .card-border-bottom-#{$label} {
  	border-color: #{$color};
  }
  .card-columns-border-bottom-#{$label} {
  	border-color: #{$color};
  }
  .card-image-border-#{$label} {
  	.img-holder {
  		border-color: #{$color};
  	}
  }
}

/**
 * Card Hover Module
 */
.card-hover {
	a {
		&:hover,
		&:focus {
			.hover-card-text {
				opacity: 1;
			}
			.hover-card-icon {
				opacity: 0;
			}
		}
	}
	.img-holder {
		position: relative;
	}
	.hover-card-text {
		background-color: $green;
		color: $white;
		opacity: 0;
		@include transition( all .3s );
	}
	.hover-card-icon {
		@include transition( all .3s );
		align-items: center;
		opacity: 1;
	}
	.card-block {
		padding: 3rem 2rem;
		h3 {
			margin-bottom: 0;
		}
		a {
			&:hover,
			&:focus {
				span, 
				i {
					color: $green;
				}
			}
		}
	}
}