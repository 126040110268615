// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

body div.gform_wrapper {
  
  ul.gform_fields {
    margin-left: -8px !important;
    margin-right: -8px !important;
    
    .ginput_container {
      margin: 0 !important;
    }
    
    .gfield {
      clear: none;
      margin: 0;
      margin-bottom: 16px;
      padding: 0 8px; 
    }
    
    
    .form-control {
      border: solid 2px #afafaf;
      border-radius: 0;
      -webkit-border-radius: 0px;
      padding: 10px 20px !important;
      height: 48px;
      &:focus {
        box-shadow: none;
        border-color: black;
      }
    }
/*
    select.form-control {
      text-indent: 10px;
    }
*/
    textarea.form-control {
      height: auto;
    }
    
  }

}

