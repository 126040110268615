.banner {
	border-bottom: 8px solid $brand-primary;
	margin-bottom: 0;
	padding: 0;
	> .container-fluid {
		width: 100%;
	}
	.navbar-header {
		float: none;
	}
	.navbar-brand {
		height: 90px;
		padding: 0;
		margin: 15px 0px;
		@include media-breakpoint-up(sm) {
			margin: 15px 15px;
		}
		@include media-breakpoint-up(lg) {
			margin: 25px 15px;
		}
		img {
			@include media-breakpoint-down(xs) {
				max-width: 220px;
			}
		}
	}
	.navbar-nav {
		border-top: 1px solid rgba( 0,0,0,.05);
		width: 100%;
		@include media-breakpoint-down(md) {
			margin: 0;
			padding: 0 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: space-between;
		}
		li {
			float: none;
			width: 100%;
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
			&.menu-blog {
				@include media-breakpoint-down(md) {
					margin-bottom: 1rem;
				}
			}
			&.menu-join {
				a {
					background-color: $brand-secondary;
					color: $white;
					display: block;
					@include media-breakpoint-down(md) {
						margin-bottom: 3rem;
						padding: 15px 10px;
						text-align: center;
						width: 140px;
					}
					&:focus,
					&:hover {
						&:after {
							content: '\25BB';
							font-family: "SS BlackTie-Regular";
							font-size: 12px;
							position: relative;
							left: 15px;
							margin-right: -7px;
							width: 7px;
						}
					}
				}
			}
			&.active {
				a {
					background: none;
					color: $brand-secondary;
					@include media-breakpoint-up(lg) {
						border-bottom: 8px solid $brand-secondary;
						position: relative;
						top: 8px;
						margin-top: -8px;
					}
					&:hover,
					&:focus {
						background: none;
						color: $brand-secondary;
						cursor: default;
					}
				}
			}
			a {
				color: $white;
				display: block;
				font-weight: 500;
				font-size: 17px;
				line-height: 22px;
				@include media-breakpoint-down(md) {
					margin-bottom: 2rem;
					padding: 0;
				}
				@include media-breakpoint-up(lg) {
					color: $gray;
					display: inline-block;
					padding: 15px 15px;
				}
			}
			&:not(.menu-join){
				a {
					@include media-breakpoint-up(lg) {
						&:hover,
						&:focus {
							border-bottom: 8px solid $brand-secondary;
							position: relative;
							top: 8px;
							margin-top: -8px;
						}
					}
				}
			}
		}
	}
}

/**
 * Social & Secondary Navigation
 */
.aux-navigation {
	float: right;
}
.secondary-navigation-wrap,
.social-navigation-wrap {
	@include media-breakpoint-up(lg) {
		float: left;
		margin-top: 50px;
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		li {
			float: left;
			list-style: none;
			@include media-breakpoint-up(lg) {
				line-height: 30px;
			}
			a {
				color: $gray;
				font-size: 15px;
				font-weight: 500;
			}
		}
	}
}
.secondary-navigation-wrap {
	@include media-breakpoint-down(md) {
		border-bottom: 2px solid $black;
		padding: 0 1.5rem 2rem;
		margin-bottom: 3rem;
	}
	@include media-breakpoint-up(lg) {
		border-right: 3px solid $brand-primary;
	}
}

li.menu-search {
	a {
		&:before {
			content: "\1F50E";
			font-family: "SS BlackTie-Bold";
			font-size: 1.5rem;
			margin-right: .9rem;
			vertical-align: middle;
		}
	}
}
li.menu-item-search {
	margin-top: -.5rem !important;
	opacity: 0;
	@include transition( all .3s ease 0 );
	display: none;
	&.display-form {
		opacity: 1;
		display: block;
	}
	form {
		p {
			margin-bottom: 0;
		}
		input {
			height: 40px;
			vertical-align: middle;
			outline: none !important;
		}
		input[type="text"] {
			border: 2px solid $gray;
			border-top-left-radius: 2rem;
			border-bottom-left-radius: 2rem;
			border-right: none;
			padding: .6rem 2rem;
			width: 20rem;
		}
		input[type="submit"] {
			background: none;
			border: 2px solid $gray;
			border-top-right-radius: 2rem;
			border-bottom-right-radius: 2rem;
			border-left: 0;
			color: $brand-secondary;
			font-family: "SS BlackTie-Bold";
			min-width: 5rem;
			display: inline-block;
		}
	}
}

.social-navigation-wrap {
	@include media-breakpoint-down(md) {
		padding-left: 15px;
	}
}

ul.secondary-nav {
	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
	}
	li {
		margin: 0;
		@include media-breakpoint-down(md) {
			float: none;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-up(lg) {
			margin: 0 20px;
		}
		&:last-child {
			@include media-breakpoint-up(lg) {
				margin-right: 40px;
			}
		}
		&:before {
			// @include media-breakpoint-down(md) {
			// 	font-family: "SS BlackTie-Bold";
			// 	margin-right: 20px;
			// }
		}
		&.menu-login {
			@include media-breakpoint-down(md) {
				order: 1;
			}
			&:before {
				// @include media-breakpoint-down(md) {
				// 	content: '\1F464';
				// 	color: $white;
				// }
			}
		}
		&.menu-shop {
			@include media-breakpoint-down(md) {
				order: 2;
			}
			// &:before {
			// 	@include media-breakpoint-down(md) {
			// 		content: '\E500';
			// 		color: $white;
			// 	}
			// }
		}
		&.menu-search {
			@include media-breakpoint-down(md) {
				order: 3;
				a {
					&:before {
						display: none;
					}
				}
			}
			// &:before {
			// 	@include media-breakpoint-down(md) {
			// 		content: '\1F50E';
			// 		color: $white;
			// 	}
			// }
		}
		&.menu-blog {
			@include media-breakpoint-down(md) {
				order: 4;
			}
		}
		&.active {
			a {
				color: $brand-secondary;
			}
		}
		a {
			@include media-breakpoint-down(md) {
				color: $white;
			}
			&:hover,
			&:focus {
				color: $brand-secondary;
				text-decoration: none;
			}
		}
	}
}
ul.header-social-menu {
	padding-left: 0;
	li {
		height: 30px;
		line-height: 30px;
		margin-right: 20px;
		padding-top: 3px;
		@include media-breakpoint-up(lg) {
			margin: 0 10px;
		}
		&:first-child {
			@include media-breakpoint-up(lg) {
				margin-left: 40px;
			}
		}
		a {
			&:before {
				color: $brand-primary;
		    -webkit-font-smoothing: antialiased;
		    font-size: 24px;
		    @include media-breakpoint-up(lg) {
		    	color: $gray;
		    }
		  }
		  &:hover,
		  &:focus {
		  	text-decoration: none;
		  	&:before {
		  		color: $brand-secondary;
		  	}
		  }
		}
	}
}

ul.social-circle {
	li {
		a:before {
			font-family: 'SSSocialCircle';
		}
	}
}
ul.social-regular {
	li {
		a:before {
			font-family: 'SSSocialRegular';
		}
	}
}
ul.social {
	li {
		a[href*="facebook.com"]::before { 
			content: '\F610'; 
		}
		a[href*="linkedin.com"]::before { 
			content: '\F612'; 
		}
		a[href*="twitter.com"]::before { 
			content: '\F611'; 
		}
		a[href*="medium.com"]::before { 
			content: '\F614'; 
		}
		a[href*="instagram.com"]::before { 
			content: '\F641'; 
		}
		a[href*="pinterest.com"]::before { 
			content: '\F650'; 
		}
		a[href*="youtube.com"]::before { 
			content: '\F630'; 
		}
	}
}

/**
 * Mobile & Tablet Navigation
 */
 button.nav-expander {
 	margin-top: 3rem;
 	opacity: 1 !important;
 	display: none;
 	outline: none !important;
 	@include media-breakpoint-down(md) {
	 	background: none;
	 	color: $black;
	 	display: block;
	 	margin-right: 0;
	 	padding: 20px 25px;
	 	position: absolute;
	 	right: 0;
	 	top: 0;
	 	z-index: 12;
	 	@include transition( right 0.3s ease-in-out 0s );
	 }
 	&:focus,
 	&:hover {
 		cursor: pointer;
 	}
	&.is-active {
		.hamburger-inner,
		.hamburger-inner:after {
			width: 25px;
		}
	}
}

.nav-expanded button.nav-expander.fixed {
 	right: 0rem;
 	z-index: 2100;
 	span {
 		opacity: 1;
 		.hamburger-inner {
 			background-color: $white;
 			&:after {
 				background-color: $white;
 			}
 			&:before {
 				background-color: $white;
 			}
 		}
 	}
}
nav.sidemenu {
	@include media-breakpoint-down(md) {
		background: $gray-dark;
		display: block;
		height: 100%;
		padding: 50px 20px 20px;
		overflow: auto;
		position: fixed;
		right: -27rem;
		top: 0;
		width: 27rem;
		z-index: 2000;
		@include transition( right 0.3s ease-in-out 0s );
	}
	@include media-breakpoint-down(sm) {
		right: -100%;
		width: 100%;
	}
	.nav-expanded & {
		@include media-breakpoint-down(md) {
			right: 0;
		}
	}
}