/**
 * Speakers 
 */
.b-speakers {
  @include media-breakpoint-down(xs) {
    padding: 3rem 0;
  }
  h1 {
    color: $text-color;
  }
  .slider {
    display: flex;
    flex-wrap: wrap;
    .slick-list {
      display: flex;
    }
    .slick-track {
      display: flex;
      &:before {
          display: none;
      }
      .slick-slide {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
      }
    }
    .note {
      height: 100%;
      padding: 3rem 2rem;
      &:before {
        border-bottom-color: $brand-secondary;
        border-left-color: $brand-secondary;
      }
    }
    > div {
      margin-bottom: 3rem;
    }
  }

  .cbp-panel {
    width: 100% !important;
  }
  .cbp-wrapper {
    margin: 3rem 0;
    .cbp-item-wrapper {
      margin-bottom: 2rem;
      a {
        color: $text-color;
      }
      .note {
        min-height: 480px;
        &:before {
          border-color: #F5F5F5 #F5F5F5 #e40046 #e40046;
        }
        img {
          @include media-breakpoint-up(md) {
            max-width: 190px;
          }
        }
      }
    }

  }
  // Fade when popoup open and not active 
  .cbp-popup-singlePageInline-open {
    .cbp-item {
      opacity: .2;
      &.cbp-singlePageInline-active {
        opacity: 1 !important;
      }
    }
  }

  // Popup
  .cbp-popup-singlePageInline {
    .cbp-l-inline {
      background-color: $white;
      font-size: $font-size-base;
      line-height: 1.75;
      padding: 4rem 3rem;
      span {
        display: block;
        &.staff-meta {
          color: #05c3de;
          font-size: 2rem;
          margin-bottom: 3.5rem;
        }
        &.event-date {
          font-size: 1.4rem;
          font-style: italic;
          margin-bottom: 3rem;
        }
      }
    }
  }
}



