html {
  font-size: 62.5%;
}

@include media-breakpoint-up(xl) {
  .container, .single-post .content {
      width: 1170px;
      max-width: 100%;
  }
}

body {
  font-size: 14px;
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

/**
 * Typography
 */
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  line-height: 1.4;
}

h3 {
  @include media-breakpoint-down(sm) {
    font-size: 2.2rem;
  }
}

p {
  margin-bottom: 1.1rem;
}

.img-responsive {
  @extend .img-fluid;
}

.big-title {
  font-size: 2.8rem;
}
.huge-text {
  font-family: 'norwesterregular';
  font-size: 7.5rem;
  line-height: 1.1 !important;
}
.medium-text {
  font-size: 2.4rem;
  line-height: 1.4 !important;
}
.small-text {
  font-size: 1.5rem;
}

ul,
ol {
  li {
    line-height: 2;
  }
  &.flex-list {
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      margin: 0 1.4rem;
      a {
        color: $white;
        font-size: 2.6rem;
      }
    }
  }
  &.list-inverse {
    li,
    a {
      color: $white !important;
    }
  }
  &.list-standard {
    li {
      font-size: 1.4rem;
      line-height: 1.4;
      margin-bottom: 1.3rem;
      a {
        color: $text-color;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
}

main.main {
  float: none;
  padding: 0;
}

.bg-repeat {
  background-repeat: repeat;
}
.bg-no-repeat {
  background-repeat: repeat;
}
.bg-cover {
  background-size: cover;
}
.text-center {
  > img {
    display: inline-block;
  }
}

.vcenter,
.column.vcenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h-100 {
  display: flex;
  height: 100%;
}

.force-100 {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}

.subtitle {
  color: #05C3DE;
  font-size: 2rem;
  margin-bottom: 4rem;
}
.clear-left {
  clear: left;
}
.highlight {
  display: inline-block;
  position: relative;
  padding: 0 3px;
  &:after {
    border-bottom: 10px solid $brand-primary;
    content: "";
    display: block;
    margin-top: -8px;
//     width: 110%;
  }
}
.featured-title {
  position: relative;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  &:after {
    background-color: $brand-primary;
    content: "";
    display: block;
    height: 5px;
    width: 120px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
  &.ft-centered {
    &:after {
      left: 50%;
      transform: translate( -50%, 0 );
    }
  }
  &.ft-small {
    &:after {
      width: 64px;
      height: 4px;
    }
  }
}
.font-small {
  font-size: 1.4rem;
  h3 {
    font-size: 2rem;
  }
}
.font-medium {
  font-size: 1.5rem;
}

.block-inverse {
  color: $white;
}

.back-to-blog {
  color: $text-color;
  display: inline-block;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.6rem;
  margin-top: 6rem;
  margin-bottom: 2rem;
  i {
    font-size: 1.4rem;
    margin-right: 1rem;
    vertical-align: middle;
  }
}

.block {
  .column {
    @include media-breakpoint-down(sm) {
      &:not(.col-sm-12) {
        margin-bottom: 3rem;
      }
    }
    &:last-child {
      @include media-breakpoint-down(xs) {
        margin-bottom: 0;
      }
    }
  }
}

/**
 * Dropdown
 */
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #1d1a1a;
  white-space: normal;
  @include media-breakpoint-up(sm) {
    white-space: nowrap;
  }
  &:focus, 
  &:hover {
    text-decoration: none;
    color: #100e0e;
    background-color: #f5f5f5;
  }
}


// Full Width Image
.col-img-wrap {
  @include media-breakpoint-up(sm) {
    padding-top: calc(  50vw - 140px );
  }
  div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vw;
    @include media-breakpoint-down(xs) {
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(sm) {
      position: absolute;
      right: 15px;
      top: 0;
      max-width: none !important;
    }
    @include media-breakpoint-up(sm) {
      height: 100%;
      width: calc(  50vw - 140px );
    }
    @include media-breakpoint-up(md) {
      width: calc(  50vw - 177px );
    }
    @include media-breakpoint-up(lg) {
      width: calc(  50vw - 210px );
    }
  }
}

// Footer
.col-img-wrap-50 {
  // @include media-breakpoint-up(sm) {
  //   padding-top: calc(  50vw - 13px );
  // }
  > div {
    background-position: center;
    @include media-breakpoint-down(xs) {
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(sm) {
      position: absolute;
      left: 0;
      top: 0;
      max-width: none !important;
    }
    @include media-breakpoint-up(sm) {
      width: 50vw;
    }
  }
}

/**
 * Social
 */
.social-shortcode {
  color: #afafaf;
  span {
    display: inline-block;
    font-weight: 200;
    margin-right: .5rem;
    text-transform: uppercase;
    vertical-align: text-bottom;
  }
  a {
    color: #afafaf;
    margin: 0 1rem;
    &:hover {
      .ss-facebook {color: #3b5998;}
      .ss-twitter {color: #55ACEE;}
    }
  }
}

/**
 * Arrow Shortcode
 */
.arrow-shortcode {
  a.more {
    color: $gray;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    .block-inverse & {
      color: $white; 
    }
    .bg-blue & {
      &:hover,
      &:focus {
        span,
        i {
          color: $gray;
        }
      }
    }
  }
  i {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
}

/**
 * Gradient
 */
 .gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com gradient-editor/#26a3da+28,9934cd+100 */
  background: #26a3da; /* Old browsers */
  background: -moz-linear-gradient(top, #26a3da 17%, #9934cd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #26a3da 17%,#9934cd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #26a3da 17%,#9934cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26a3da', endColorstr='#9934cd',GradientType=0 ); /* IE6-9 */
  .speakers-intro {
    color: $white;
    a {
      color: $white;
    }
  }
  .interactive-button {
    
  }
}

/**
 * Colors
 */
$colors: (red, '#e40046'),
         (blue, '#05c3de'),
         (orange, '#ff8f1c'),
         (purple, '#9B26B6'),
         (green, '#8EDD65'),
         (gray-dark, $gray-dark),
         (gray, $gray),
         (gray-light, $gray-light),
         (gray-lighter, $gray-lighter),
         (gray-lighter-alt, $gray-lighter-alt),
         (white, $white),
         (black, $black);

@each $label, $color in $colors {
  .color-#{$label} {
    color: #{$color} !important;
  }
  .bg-#{$label} {
    background-color: #{$color} !important;
    .note:before {
      border-top-color: #{$color};
      border-right-color: #{$color};
    }
  }
  .border-left-#{$label} {
    border-color: #{$color};
  }
  .featured-title.ft-#{$label} {
    &:after {
      background-color: #{$color};
    }
  }
  .card-hover-#{$label} {
    .hover-card-text {
      background-color: #{$color} !important;
    }
    a:hover {
      i {
        color: #{$color} !important;
      }
    }
  }
}



.loop-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  i {
    color: #161414;
    font-size: 4.2rem;
  }
}

.table-responsive {
  @include media-breakpoint-up(md) {
    display: table;
  }
}