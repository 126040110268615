/**
 * Common
 */
.blog,
.category,
.search,
.single {
	.author {
		margin-bottom: 2rem;
		a {
			color: $green;
			font-size: 1.4rem;
			text-transform: uppercase;
			&[rel=author] {
				margin-right: 1rem;
			}
			&.post-category {
				color: $gray-light;
			}
		}
		time {
			color: $gray-light;
			font-size: 1.4rem;
			margin-right: 1.2rem;
		}
	}
}
/**
 * Archive
 */
.blog,
.category {
	background-color: $gray-lighter;
	// .page-header-blog {
	// 	background-size: cover;
	// 	background-position: center;
	// 	background-repeat: no-repeat;
	// 	@include media-breakpoint-up(sm) {
	// 		min-height: 30vw;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 	}
	// }
	.page-header {
		padding: 0 15px;
		width: 100%;
	}
	.post-filters {
		.selectric-wrapper {
			min-width: 200px;
		}
		.selectric {
			height: 45px;
			.label {
				font-size: 1.6rem;
				font-weight: 400;
				line-height: 45px;
				height: 45px;
				padding: 0;
				text-align: left;
			}
			b {
				color: $gray-light;
				font-size: 2rem;
				height: 45px;
				font-family: 'SSGizmo';
				position: absolute;
				right: 1.5rem;
				top: 60%;
				transform: translate( 0, -50% );
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
	.search-bar {
		display: flex;
		justify-content: space-between;
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			padding: 2rem 0;
			form.search-form {
				margin-bottom: 2rem;
				label {
					margin-bottom: 0;
				}
			}
			form.post-filters {
				order: 2;
			}
		}
	}
	.search-form {
		.search-field {
			border: 1px solid $gray-light;
			border-right: none;
			border-radius: 0;
			height: 45px;
		}
		.search-btn {
		  border-radius: 0;
		  border: 1px solid $gray-light;
		  border-left: none;
		  background-color: $white;
		  color: $gray-light;
		  display: inline-block;
		  height: 45px;
		  margin-left: -5px;
		  position: relative;
		  width: 45px;
		  min-width: auto;
		  font-family: "SSGizmo";
		  padding: 0;
		  vertical-align: middle;
		}
	}
	.post-wrap {
		&:nth-child(even) {
			@include media-breakpoint-up(sm) {
				padding-left: 3.5rem;
				padding-right: 0;
			}
		}
		&:nth-child(odd) {
			@include media-breakpoint-up(sm) {
				padding-left: 0;
				padding-right: 3.5rem;	
			}
		}
		.post-list {
			background-color: $white;
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;
		}
	}
	.post-thumbnail-wrap {
		min-height: 50vw;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		@include media-breakpoint-up(sm) {
			min-height: 17.6vw;
		}
		a {
			display: block;
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			height: 100%;
		}
	}
	.post-description {
		padding: 3rem 2rem;
		h2 {
			margin-bottom: 0.5rem;
			a {
				color: $text-color;
			}
		}
	}
	.widget_recent_entries {
		display: none;
	}
}

/**
 * Single
 */
 .single-post {
 	background-color: $gray-lighter;
 	.content {
 		@extend .container;
 	}
 	.img-responsive {
 		@include media-breakpoint-up(md) {
 			width: 100%;
 		}
 	}
 	.social-shortcode {
 		a {
 			margin-right: 0;
 			margin-left: .8rem;
 			i {
 				color: #cacaca;
 				font-size: 2.8rem;
 			}
 			&:hover,
 			&:focus {
 				i {
 					color: darken( #cacaca, 10% );
 				}
 			}
 		}
 	}
 	.post-description {
 		background-color: $white;
 		padding: 3rem;
 		margin-bottom: 2rem;
 		header {
 			position: relative;
 		}
 		h1 {
 			margin-bottom: 0;
 			padding-right: 13rem;
 		}
 		.post-category {
 			margin-right: 1.5rem;
 		}
 		.social-shortcode {
 			position: absolute;
 			right: 0;
 			top: 0;
 		}
 	}
 	.post-categories {
 		margin-bottom: 1rem;
 		@include clearfix();
 		padding-left: 0;
 		li {
 			list-style: none;
 			float: left;
 			margin-right: 2rem;
 			a {
 				text-transform: uppercase;
 			}
 		}
 	}
 	.post-footer {
 		background-color: $white;
 		margin-bottom: 10rem;
 		padding: 3rem;
 	}
 	.post-footer-header {
 		border-bottom: 2px solid $gray-lighter;
 		padding-bottom: 0rem;
 		margin-bottom: 3rem;
 		@include media-breakpoint-up(sm) {
 			display: flex;
 			justify-content: space-between;
 		}
 	}
 	.post-author {
 		h5 {
 			color: $brand-primary;
 			margin-bottom: 0;
 			text-transform: uppercase;
 		}
 	}
 	blockquote {
 		border-left: 0;
 		position: relative;
 		&:before {
 			color:$brand-primary;
 			content: "”";
 			font-size: 6.4rem;
 			font-family: $font-family-sans-serif;
 			position: absolute;
 			left: -4rem;
 			top: 60%;
 			transform: translate( 0, -50% );
 		}
 	}
 	.back-to-blog {
 		color: $text-color;
 		display: inline-block;
 		height: 2.5rem;
 		line-height: 2.5rem;
 		font-size: 1.6rem;
 		margin-top: 6rem;
 		margin-bottom: 2rem;
 		i {
 			font-size: 1.4rem;
 			margin-right: 1rem;
 			vertical-align: middle;
 		}
 	}
 	aside.sidebar {
 		margin-top: 105px;
 	}
 	.widget_recent_entries {
 		padding: 2rem;
 		background-color: $white;
 		margin-bottom: 3rem;
 		ul {
 			padding-left: 0;
 			li {
 				list-style: none;
 				a {
 					color: $text-color;
 					font-size: 1.4rem;
 				}
 			}
 		}
 	}
 }

 /**
  * Author Archive
  */
.author.archive {
	.page-header {
		margin: 3rem 0 3rem;
	}
}