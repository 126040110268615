.tribe-event-url {
	color: $brand-primary;
}

.post-type-archive-tribe_events {
	.page-header {
		
	}
}
#tribe-events {
	background-color: $gray-lighter;
	h2.tribe-events-page-title {
		font-size: 2.4rem;
		font-weight: 400;
		text-align: left;
		@include media-breakpoint-up(md) {
			margin-bottom: 5rem;
		}
	}
}

#tribe-events-bar {
	margin-bottom: 0;
	@include media-breakpoint-up(sm) {
		display: flex;
		justify-content: space-between;
	}
	.event-category-dropdown {
		min-width: 180px;
		button {
			background-color: $white;
			border-radius: 0;
			border-color: $gray-light;
			line-height: 33px;
			min-height: 45px;
			text-align: left;
			width: 100%;
			span {
				color: $gray-light;
				float: right;
				font-size: 2.1rem;
			}
		}
		.dropdown-menu {
			border-top: none;
			border-color: $gray-light;
			border-radius: 0;
			top: 44px;
			left: 0;
			margin-top: 0;
			min-width: 180px;
			padding-bottom: 0;
			li {
				a {
					padding: .6rem 1.6rem;
				}
			}
		}
	}
}

#tribe-bar-form {
	width: auto;
	#tribe-bar-collapse-toggle {
		display: none;
	}
	.tribe-bar-filters {
		float: none;
		.tribe-bar-search-filter {
			border: 1px solid $gray-light;
			padding: 0;
			margin-bottom: 2rem;
			@include media-breakpoint-up(sm) {
				display: flex;
			}
			label {
				display: none;
			}
			input,
			button {
				background-color: $white;
				border: none;
				min-height: 45px;
			}
			input {
				font-style: normal;
				margin: 0;
				padding: .8rem 1.6rem;
				outline: none;
			}
			button {
				color: $gray-light;
				font-size: 2.1rem;
				padding: .8rem 1.2rem;
			}
		}
	}
}

#tribe-events-content-wrapper {
	@include media-breakpoint-up(md) {
		padding: 7rem 0;
	}
	#tribe-events-content {
		background-color: $gray-lighter;
		margin-bottom: 0;
		.tribe-events-loop {
			max-width: none;
		}
		.tribe-events-list-separator-month {
			display: none;
		}
		.tribe-events-loop {
			margin: 0 -15px;
			@include media-breakpoint-up(md) {
				display: flex;
				flex-wrap: wrap;
			}
			> div {
				@include media-breakpoint-up(md) {
			    flex: 0 0 50%;
			    max-width: 50%;
			  }
			}
			.type-tribe_events {
				padding: 0 15px;
				margin-bottom: 4rem;
				&:first-of-type {
					margin: 0 0 40px;
					padding-top: 0;
				}
				.flex-row {
					background-color: $white;
					height: 100%;
					border-bottom: 6px solid $brand-primary;
				}
				.events-meta {
					background-color: $black;
					color: $white;
					.tribe-events-event-meta {
						height: 100%;
						margin-bottom: 0;
						margin-top: 0;
						.tribe-event-schedule-details {
							padding: 2rem 0 2.4rem;
							@include media-breakpoint-up(md) {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								height: 100%;
							}
							.event-date {
								font-weight: 400;
								font-size: 2rem;
								line-height: 1;
							}
							.event-month {
								display: block;
								font-size: 2.4rem;
								font-weight: 500;
								line-height: 1;
								letter-spacing: .1em;
								text-transform: uppercase;
							}
							.event-year {
								color: $brand-primary;
								font-size: 2.4rem;
								font-weight: 400;
								line-height: 1;
							}
						}
					}
				}
				.events-content {
					padding: 2rem 2rem 1rem;
					h2 {
						font-size: 2rem;
						margin-bottom: 4rem;
					}
				}
			}
		}
		.tribe-events-ical {
			display: none;
		}
	}
}

#tribe-events-header {
	.wp-pagenavi {
		display: none;
	}
}
#tribe-events-footer {
	.wp-pagenavi {
		display: flex;
		justify-content: center;
		align-items: center;
		// &:before {
		// 	content:"D";
		//   visibility: hidden;
		//   margin-right: auto;
		// }
		.pages {
			display: none;
		}
		a.previouspostslink {
			margin-right: auto;
		}
		a.nextpostslink {
			margin-left: auto;
		}
		a {
			display: flex;
		}
	}
	.tribe-events-sub-nav {
		display: none;
	}
}