.b-hover-card {
	h2 {
		margin-bottom: 4rem;
	}
	.card-img-holder {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 30vw;
		@include media-breakpoint-up(sm) {
			min-height: 20vw;
		}
	}
}