.b-group-shortcuts {
	padding: 3 0;
	.group-card-container {
		height: 17.5rem;
		.group-card {
			border: none;
			position: relative;
			overflow: hidden;
		}
	}
	a {
		.flipping-bg {
			@include transition( all .3s ease 0s );
			position: absolute;
			top: 0;
			right: calc(100% - 15px);
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		span {
			@include transition( color .5s ease 0s, transform .3s ease 0s );
		}
		&:hover,
		&:focus {
			span {
				color: $white;
				&.group-title {
					text-decoration: none;
				}
				&.ss-gizmo {
					transform: translate( 10px, 0 );
				}
			}
			.flipping-bg {
				right: 0;
			}
		}
	}
	.group-shortcuts-slider {
		margin: 40px -15px 60px;
	}
}