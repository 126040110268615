button, input, optgroup, select, textarea {
	font-family: inherit;
}
.btn-default.active, 
.btn-default:active, 
.show>.btn-default.dropdown-toggle, 
.search-form .active.search-submit, 
.search-form .show>.dropdown-toggle.search-submit, 
.search-form .search-submit:active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.btn-default, .search-form .search-submit {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.interactive-button {
	@include transition( all .3s ease 0s );
	&:after {
		font-family: 'SS BlackTie-Bold';
		position: absolute;
    top: 50%;
    opacity: 0;
    margin-top: -10px;
    @include transition( all .3s ease 0s );
	}
	&:hover,
	&:focus {
		span {
			color: #05C3DE;
			transform: translate(-2px, 0 );
			.gradient &,
			.inverse & {
				color: $text-color;
			}
		}
		i {
			transform: translate(5px, 0 );
			color: #05C3DE;
			.gradient &,
			.inverse & {
				color: $text-color;
			}
		}
		&:after {
			opacity: 1;
		}
	}
	i, 
	span {
		display: inline-block;
		font-size: 1.4rem;
		@include transition( all .3s ease 0s );
	}
	i {

	}
}
.btn-block {
	display: block;
}
.btn.btn-center-mobile {
	@include media-breakpoint-down(xs) {
		display: flex;
		margin: 3rem auto;
		justify-content: center;
	}
}
.btn {
	@extend .interactive-button;
	border-width: 2px;
	border-style: solid;
	border-radius: 25px;
	font-size: 1.4rem;
	display: inline-block;
	padding: 10px 12px;
	position: relative;
	min-width: 180px;
	max-width: 240px;
	&.btn-sm {
		min-width: 160px;
	}
	&.btn-lg {
		min-width: 220px;
		span {
			font-size: 1.6rem;
		}
	}

	&.btn-primary,
	&.btn-secondary {
		&:after {
			content: '\25BB';
			right: 23px;
		}
		&:hover,
		&:focus {
			&:after {
				right: 18px;
			}
			span {
				color: $white;
				transform: translate(-12px, 0 );
			}
		}
		span {
			font-size: 1.6rem;
		}
	}

	&.btn-primary {
		border-color: #62BD33;
		&:hover,
		&:focus {
			background-color: #62BD33;
			color: $white;
		}
	}
	&.btn-secondary {
		background-color: $brand-secondary;
		border-color: #7720a2;
		color: $white;
		&:hover,
		&:focus {
			background-color: #7720A2;
			color: $white;
		}
	}

	&.btn-outline-white {
		background-color: transparent;
		border-color: $white;
		@extend .btn-primary;
		span {
			color: $white;
		}
		&:after {
			color: $gray-dark;
		}
		&:hover,
		&:focus {
			background-color: $white;
			border-color: $white;
			span {
				color: $gray-dark;
			}
		}
	}
}
$colors: (green, $brand-primary),
				 (red, '#e40046'),
         (blue, '#05c3de'),
         (orange, '#ff8f1c'),
         (purple, $brand-secondary),
         (primary, $brand-primary),
         (secondary, $brand-secondary);

@each $label, $color in $colors {
	.btn.btn-outline-#{$label} {
		background-color: transparent;
		border-color: #{$color};
		@extend .btn-secondary;
		span {
			color: #{$color};
		}
		&:after {
			color: $white;
		}
		&:hover,
		&:focus {
			background-color: #{$color};
			border-color: #{$color};
			span {
				color: $white;
			}
		}
	}
	.btn.btn-white-#{$label} {
		background-color: $white;
		border-color: $white;
		@extend .btn-outline-white;
		span {
			color: #{$color};
		}
		&:after {
			color: #{$color};
		}
		&:hover,
		&:focus {
			color: #{$color};
			span {
				color: #{$color};
			}
		}
	}
	.btn.btn-outline-white-#{$label} {
		background-color: transparent;
		border-color: $white;
		@extend .btn-outline-white;
		span {
			color: $white;
		}
		&:after {
			color: #{$color};
		}
		&:hover,
		&:focus {
			color: #{$color};
			span {
				color: #{$color};
			}
		}
	}
}