.b-content {
  // background-repeat: no-repeat;
  padding: 5rem 0;
  @include media-breakpoint-up(md) {
	  padding: 9rem 0;
	}
	h3 {
		margin-bottom: 2rem;
	}
	p {
		line-height: 1.75;
	}
	img {
		@include media-breakpoint-down(sm) {
			margin-bottom: 2rem;
		}
	}
	.btn {
		margin-top: 3rem;
	}
}