.sub-footer {
	h3 {
		color: $white;
		font-size: 2.4rem;
		@include media-breakpoint-up(sm) {
			margin-bottom: 2.5rem;
		}
	}
	p {
		line-height: 1.5;
		font-size: 1.5rem;
	}
	.pencil-bg {
		position: relative;
		padding: 3rem 0;
		@include media-breakpoint-down(xs) {
			order: 2;
		}
		.home-pencil {
			position: absolute;
			right: 75%;
			top: auto;
			background-size: cover;
			bottom: 43px;
			@include media-breakpoint-up(lg) {
				bottom: auto;
				top: 240px;
			}
		}
	}
	.col-img-wrap-50 {
		min-height: 50vw;
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		@include media-breakpoint-up(sm) {
			min-height: 23vw;
		}
		> div {
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			overflow: hidden;
		}
	}
}
.content-info {
	background-color: $gray-dark;
	padding: 0px 15px;
	.container {
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
	.footer-top {
		border-bottom: 2px solid $black;
		padding: 35px 0;
		@include media-breakpoint-up(md) {
			padding: 50px 0;
		}
		.newsletter-signup {
			color: $gray-light;
			h3 {
				color: $white;
				font-size: 20px;
				margin-bottom: 2rem;
				@include media-breakpoint-up(md) {
					font-size: 20px;
				}
			}

		}
	}
	.footer-bottom {
		color: $white;
		padding: 30px 0 45px;
		display: flex;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
			justify-content: space-between;
		}
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			padding: 30px 0 25px;
		}
		p, a {
			font-size: 13px;
		}
	}
	#menu-footer-first {
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}
	#menu-footer-second {
		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}
	.footer-menu {
		padding-left: 0;
		li {
			margin-bottom: 0px;
			&.active {
				a {
					color: $brand-secondary;
				}
			}
			a {
				color: $white;
				font-size: 1.4rem;
				&:hover,
				&:focus {
					color: $brand-secondary;
					text-decoration: none;
				}
			}
		}
	}
	.footer-social-menu {
		@include media-breakpoint-down(sm) {
			border-bottom: 2px solid $black;
			padding-bottom: 1rem;
			margin-bottom: 3rem;
			width: 100%;
			flex: 1 1 auto;
		}
		@include media-breakpoint-up(md) {
			order: 2;
		}
		ul {
			padding-left: 0;
			margin-bottom: 0;
			@include media-breakpoint-down(sm) {
				padding: 0 10%;
				text-align: center;
			}
			@media ( max-width: 350px ) {
				padding: 0 5%;
			}
			li {
				float: left;
				margin: 0 25px;
				list-style: none;
				@include media-breakpoint-down(sm) {
					margin-bottom: 2rem;
					float: none;
					display: inline-block;
				}
				a {
					color: $brand-primary;
					font-size: 18px;
					&:hover,
					&:focus {
						color: $brand-secondary;
						text-decoration: none;
					}
				}
			}
		}
	}
	.copy {
		@include media-breakpoint-down(sm) {
			align-self: flex-start;
		}
		@include media-breakpoint-up(md) {
			order: 1;
		}
		p {
			// line-height: 20px;
			padding-top: 8px;
		}
	}
	.footer-toc {
		@include media-breakpoint-down(sm) {
			align-self: flex-end;
		}
		@include media-breakpoint-up(md) {
			order: 3;
		}
		#menu-footer-bottom {
			@include media-breakpoint-up(md) {
				display: flex;
				li {
					margin-left: 2rem;
				}
			}
		}
	}
	.footer-bottom-menu {
		padding-left: 0;
		li {
			list-style: none;
			height: 20px;
			a {
				font-size: 13px;
				color: $white;
				line-height: 20px;
			}
		}
	}
}
.newsletter-signup-wrap {
	@include media-breakpoint-down(sm) {
		border-top: 2px solid $black;
		clear: both;
		padding-top: 3rem;
	}
	.newsletter-signup {
		font-size: 1.4rem;
	}
}

#gform_wrapper_2 {
	margin-top: 4rem;
	.top_label .gfield_label {
		display: none;
	}
}
#gform_2 {
	display: flex;
	flex-wrap: nowrap;
	.gform_body {
		width: 75%;
	}
	.gform_footer {
		margin-top: 0;
		padding: 0;
		width: 25%;
		button {
			background-color: $brand-secondary;
			border: none;
			color: $white;
			font-size: 2.4rem;
			font-family: "SS BlackTie-Bold";
			height: 48px;
			width: 100%;
		}
	}
}